import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { Button } from '@progress/kendo-react-buttons';
import { Card, StackLayout } from "@progress/kendo-react-layout";
import { Error, FloatingLabel } from "@progress/kendo-react-labels";
import { emailValidator, requiredValidator } from "utils/FormValidators";
import { useNotification } from "components/Notifications/NotificationProvider";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import ParamService from "services/ParamService";
import { ParamDTO } from "model/ParamDTO";


const ParamFormView = () => {


    const navigate = useNavigate();
    let { id } = useParams();


    const notify = useNotification();

    const [touchedCN, setTouchedCN] = useState(false)
    const [touchedCE, setTouchedCE] = useState(false)

    const [validationMessageCN, setValidationMessageCN] = useState("")
    const [validationMessageCE, setValidationMessageCE] = useState("")

    const [params, setParams] = useState<ParamDTO>()

    useEffect(() => {
        handleInit()
    }, [])

    function handleInit() {
        ParamService.getAllParams()
            .then((result) => {
                if (result) {
                    setParams(result)
                } else {
                    
                notify({ message: "Aucun paramètre n'a été trouvé", type: "error" })
                }

            })
            .catch((error) => {
                notify({ message: error, type: "error" })
            })
    }

    useEffect(() => {
        setValidationMessageCE(emailValidator(params?.contactEmail))
        setValidationMessageCN(requiredValidator(params?.contactName))
    }, [params])

    function handleCNChange(event: InputChangeEvent) {
        console.log(event)
        if (event && params)
            setParams({ ...params, contactName: event.value })
    }
    function handleCEChange(event: InputChangeEvent) {
        if (event && params)
            setParams({ ...params, contactEmail: event.value })
    }


    const showCNValidationMessage: string | false | null =
        touchedCN && validationMessageCN;
    const showCEValidationMessage: string | false | null =
        touchedCE && validationMessageCE;
    const errorCNId: string = showCNValidationMessage ? `${id}_error` : "";
    const errorCEId: string = showCEValidationMessage ? `${id}_error` : "";

    function handleTouchCN() {
        setTouchedCN(true)
    }
    function handleTouchCE() {
        setTouchedCE(true)
    }

    function handleClick() {
        if (params) {
            ParamService.putParam(params.id, params)
                .then(() => {
                    notify({ message: "Paramètres mis à jour avec succès", type: "success" })
                })
                .catch((error) => {
                    notify({ message: error, type: "error" })
                })
        }
    }

    return (
        <div className='wrapper'>
            <div className="row" style={{ justifyContent: "space-between", alignItems: "center", height: "40px" }}>
                <span className="section-title">Paramètres</span>
            </div>
            <Card>
                <div>

                    <div className='form-section'>
                        <div className="param-section">
                            <legend className={"k-form-legend"}>Contact Administrateur de l'application</legend>
                            <StackLayout
                                orientation="horizontal"
                                gap={20}>
                                <div className="col">
                                    <FloatingLabel
                                        editorId="contactName"
                                        editorValue={params?.contactName}
                                        label="Nom"
                                        className="popup-label"
                                    >
                                        <Input
                                            id={"contactName"}
                                            value={params?.contactName}
                                            valid={!validationMessageCN || !touchedCN}
                                            placeholder={"Entrez un Nom et un Prénom"}
                                            onFocus={handleTouchCN}
                                            onChange={handleCNChange}
                                        />
                                    </FloatingLabel>
                                    {showCNValidationMessage && (
                                        <Error id={errorCNId}>{validationMessageCN}</Error>
                                    )}
                                </div>
                                <div className="col">
                                    <FloatingLabel
                                        editorId="contactEmail"
                                        editorValue={params?.contactEmail}
                                        label="Email"
                                    >
                                        <Input
                                            id={"contactEmail"}
                                            value={params?.contactEmail}
                                            valid={!validationMessageCE || !touchedCE}
                                            placeholder={"Entrez un email"}
                                            onFocus={handleTouchCE}
                                            onChange={handleCEChange}
                                        />
                                    </FloatingLabel>
                                    {showCEValidationMessage && (
                                        <Error id={errorCEId}>{validationMessageCE}</Error>
                                    )}
                                </div>
                            </StackLayout>
                        </div>
                    </div>
                    <div className="k-form-buttons">
                        <Button
                            name="cancel"
                            type="button"
                            fillMode="outline"
                            onClick={() => { navigate(-1) }}
                        >
                            Retour
                        </Button>
                        <Button
                            onClick={handleClick}
                            disabled={(validationMessageCE || validationMessageCN) ? true : false}
                            themeColor={"primary"}
                        >
                            Enregistrer
                        </Button>
                    </div>

                </div>
            </Card>

        </div >
    )
}

export default ParamFormView;