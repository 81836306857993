import { setLoading, setLoggedIn } from "store/slices/globalSlice";
import instance from "./index";

let notificationFunction: Function = () => {};
const setNotificationFunction = (f: Function) => {
  notificationFunction = f;
};

const setUpInterceptor = (store: any) => {
  const disableOpenIDAuth = process.env.REACT_APP_DISABLE_OPENID_AUTH;

  if (disableOpenIDAuth === "true") {
    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config) {
      const user = store.getState().user;
      store.dispatch(setLoading(true));
      if (user) {
        const head = config.headers;
        head !== undefined
          ? (head.Authorization = user.token ? `Bearer ${user.token}` : "")
          : console.error("undefined header");
      }
      return config;
    });
  } else {
    // Set the anti forgery token for any request
    instance.interceptors.request.use(function (config) {
      const user = store.getState().user;
      store.dispatch(setLoading(true));
      if (user) {
        const head = config.headers;
        head !== undefined
          ? (head["X-XSRF-TOKEN"] = store.getState().antiForgeryToken)
          : console.error("undefined header");
      }
      return config;
    });
  }
  instance.interceptors.response.use(
    function (response) {
      store.dispatch(setLoading(false));
      return response;
    },
    function (error) {
      store.dispatch(setLoading(false));
      /*if (error?.response?.data) {
        const msg = error.response.data.message ? error.response.data.message : error.response.data.title 
        notificationFunction({
          type: "error",
          message: msg
        });  
      } else if(error?.message) {
        notificationFunction({
          type: "error",
          message: error?.message
        });  
      }*/
      if (error.response && 401 === error.response.status) {
        console.log(error);
        store.dispatch(setLoggedIn(false));
      }
      return Promise.reject(error);
    }
  );
};

export { setNotificationFunction, setUpInterceptor };
