import { SchedulerSlot, SchedulerSlotProps } from "@progress/kendo-react-scheduler";

const CustomSlot = (props: SchedulerSlotProps) => {
    const resource: any = props.group.resources[0]
    return (
        <SchedulerSlot
            {...props}
            style={{
                ...props.style,
                height: "34px",
            }}
        />
    )
};

export default CustomSlot;