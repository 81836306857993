import { useEffect, useRef, useState } from 'react';
import { RootState } from 'store';
import { connect } from 'react-redux';
import { ModalityDTO } from '../../model/ModalityDTO';
import { OccupationService } from 'services/OccupationService';
import { OccupationDTO } from '../../model/OccupationDTO';
import { Scheduler, WeekView } from '@progress/kendo-react-scheduler';
import { formatOccupation } from './ScheduleHelper';

import moment from 'moment';
import { Button } from '@progress/kendo-react-buttons';
import { Popup } from '@progress/kendo-react-popup';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { RequestTypes } from 'utils/RequestHelpers';
import { Status } from 'model/StatusEnum';
import { FloatingLabel } from '@progress/kendo-react-labels';
import { ExportedScheduler } from './ExportedScheduler';
import CustomDateHeader from 'components/CustomScheduler/CustomDateHeader';
import CustomViewItem from 'components/CustomScheduler/CustomViewItem';
import CustomSlot from 'components/CustomScheduler/CustomSlot';
import CustomResourceItem from 'components/CustomScheduler/CustomResourceItem';
import { ResourceDTO } from 'model/ResourceDTO';
import { useNotification } from 'components/Notifications/NotificationProvider';
import { UserDTO } from 'model/UserDTO';
import { EquipmentDTO } from 'model/EquipmentDTO';
import { EquipmentType, EquipmentTypeMap } from 'model/EquipmentType';
import { QualificationMap } from 'model/Qualification';

const mapStateToProps = function (state: RootState) {
    return {
        user: state.user,
        loading: state.loading
    }
}
type EquipmentFilter = {
    modality: ModalityDTO | null,
    resource: EquipmentDTO | null,
    type: { id: number, nom: string } | null,
}
type OccupationFilter = {
    requestType: {
        name: string,
        id: number
    } | null,
    requestStatus: {
        name: string,
        id: number
    } | null,
    qualification: { id: number, nom: string } | null
}
type Period = {
    startDate: Date | null,
    endDate: Date | null,
}


async function getOccupations(period: Period, type: string, occupationFilter: OccupationFilter, resourceFilter: EquipmentFilter, user: UserDTO) {
    const occupationService = new OccupationService()
    const startDate = moment(period.startDate)
    const endDate = moment(period.endDate)
    return await occupationService.getAllBetween(startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"), null, type)?.then((data: any) => data?.content.filter((occupation: OccupationDTO) => applyOccupationFilter(occupation, occupationFilter, resourceFilter, user)))
}
const applyOccupationFilter = (occupation: OccupationDTO, occupationFilter: OccupationFilter, resourceFilter: EquipmentFilter, user: UserDTO) => {
    console.log(occupation);

    const result1 = occupationFilter && occupationFilter.requestStatus ? occupation.statut === occupationFilter.requestStatus?.id : true
    const result2 = occupationFilter && occupationFilter.requestType ? occupation.demande.type === occupationFilter.requestType?.id : true
    const result5 = occupationFilter && occupationFilter.qualification ? occupation.demande.qualification === occupationFilter.qualification?.id : true
    const result4 = resourceFilter.resource ? occupation.ressource.id === resourceFilter.resource.id : true
    const result6 = user ? (user.role === "IC" || user.role === "BLM") ? user.modalites.find((mod) => occupation.ressource.modaliteId === mod.id) : true : false
    return result1 && result2 && result4 && result5 && result6
};

function transformOccupationsToItems(occupations: OccupationDTO[]) {
    return occupations?.map((occupation) => formatOccupation(occupation))
}
const getSelectedResources = (resources: ResourceDTO[], resourceFilter: EquipmentFilter) => {
    return resources.filter((resource: ResourceDTO) => {
        const result1 = resourceFilter.type ? resource.type === resourceFilter.type.id : true
        const result2 = resourceFilter.resource ? resource.id === resourceFilter.resource.id : true

        return result1 && result2
    })
};

const ResourceSchedulePanel = (props: any) => {
    const resourceService = props.resourceService
    const type = resourceService.getType()
    const typeLabel = resourceService.getTypeLabel()[0]
    const title = resourceService.getTypeLabel()[1]

    const initResourceFilters = {
        modality: null,
        resource: null,
        type: EquipmentTypeMap.find(e => e.id === EquipmentType.ECHO) || null
    }
    const initOccupationFilters = {
        requestType: null,
        requestStatus: null,
        qualification: null
    }

    const [resourceFilter, setResourceFilter] = useState<EquipmentFilter>(initResourceFilters)
    const [occupationFilter, setOccupationFilter] = useState<OccupationFilter>(initOccupationFilters)
    const [allResources, setAllResources] = useState<ResourceDTO[]>([])
    const [selectedResources, setSelectedResources] = useState<ResourceDTO[]>([])
    const [occupations, setOccupations] = useState<OccupationDTO[]>([])
    const [renderExport, setRenderExport] = useState(false)
    const [period, setPeriod] = useState<Period>({ startDate: null, endDate: null });

    const notify = useNotification()
 console.log(EquipmentTypeMap);
 
    useEffect(() => {
        if (period.startDate && period.endDate) {
            getOccupations(period, type, occupationFilter, resourceFilter, props.user).then((result: any) => {
                const filteredResources = getSelectedResources(allResources, resourceFilter)
                setOccupations(result)
                setSelectedResources(filteredResources)
            }).catch((error) => notify({ message: error, type: "error" }))

        }

    }, [period, resourceFilter, occupationFilter, allResources, type])

    const colWidth = (document.getElementsByClassName("k-scheduler-cell k-group-cell k-heading-cell")[0]?.clientWidth);

    const ResourceHeader = () => {

        const user = props.user
        let datesStart = period?.startDate
        let datesEnd = period?.endDate
        const anchor = useRef<HTMLButtonElement | null>(null);
        const anchorFilters = useRef<HTMLButtonElement | null>(null);
        const [show, setShow] = useState(false);
        const [showFilters, setShowFilters] = useState(false);

        function handleClick() {
            setShow(!show)
        }
        function handleFilter() {
            setShowFilters(!showFilters)
        }
        function handleReqTypes(event: DropDownListChangeEvent) {
            setOccupationFilter(({ ...filter }) => ({ ...filter, requestType: event.value }))
        }
        function handleQualification(event: DropDownListChangeEvent) {
            setOccupationFilter(({ ...filter }) => ({ ...filter, qualification: event.value }))
        }
        function handleEquTypes(event: DropDownListChangeEvent) {
            setResourceFilter(({ ...filter }) => ({ ...filter, type: event.value }))
        }
        function handleEquStatus(event: DropDownListChangeEvent) {
            setOccupationFilter(({ ...filter }) => ({ ...filter, requestStatus: event.value }))
        }
        function handleEquEqu(event: DropDownListChangeEvent) {
            setResourceFilter(({ ...filter }) => ({ ...filter, resource: event.value }))
        }


        const width = (document.getElementById("scheduler-div")?.clientWidth) || 0;

        return (
            <div className='k-widget k-toolbar k-scheduler-toolbar'>
                <div className='scheduler-filters' style={{ width: colWidth }}>
                    <button className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base" onClick={handleFilter} ref={anchorFilters}>
                        <span>Filtres</span>
                        <i className="icon-arrow-down-medium" />
                    </button>
                    <Popup anchor={anchorFilters.current} className="popup-real" show={showFilters} popupClass={"popup-content"}>
                        <span style={{ fontWeight: 800, fontSize: "18px" }}>Filtrer le planning</span>
                        <div className='popup-scheduler-filters'>
                            <FloatingLabel
                                id='requestTypeLabel'
                                label={"Type de Demande:"}
                                editorValue={occupationFilter.requestType?.name}
                            >
                                <DropDownList
                                    defaultItem={null}
                                    ariaLabelledBy={"requestTypeLabel"}
                                    data={RequestTypes}
                                    textField={"name"}
                                    dataItemKey={"id"}
                                    value={occupationFilter.requestType}
                                    onChange={handleReqTypes} />
                            </FloatingLabel>
                            <FloatingLabel
                                id='requestStatusLabel'
                                label={"Statut:"}
                                editorValue={occupationFilter.requestStatus?.name}
                            >
                                <DropDownList
                                    defaultItem={null}
                                    ariaLabelledBy={"requestStatusLabel"}
                                    data={Status}
                                    textField={"name"}
                                    dataItemKey={"id"}
                                    value={occupationFilter.requestStatus}
                                    onChange={handleEquStatus} />
                            </FloatingLabel>
                            <FloatingLabel
                                id='equipmentTypeLabel'
                                label={"Type d'équipement :"}
                                editorValue={resourceFilter.type?.nom}
                            >
                                <DropDownList
                                    defaultItem={null}
                                    ariaLabelledBy={"equipmentTypeLabel"}
                                    data={EquipmentTypeMap}
                                    textField={"nom"}
                                    dataItemKey={"id"}
                                    value={resourceFilter.type}
                                    onChange={handleEquTypes} />
                            </FloatingLabel>
                            <FloatingLabel
                                id='qualificationLabel'
                                label={"Qualification :"}
                                editorValue={occupationFilter.qualification?.nom}
                            >
                                <DropDownList
                                    defaultItem={null}
                                    ariaLabelledBy={"qualificationLabel"}
                                    data={QualificationMap}
                                    textField={"nom"}
                                    dataItemKey={"id"}
                                    value={occupationFilter.qualification}
                                    onChange={handleQualification} />
                            </FloatingLabel>
                            <FloatingLabel
                                id='resourceLabel'
                                label={typeLabel + ":"}
                                editorValue={resourceFilter.resource?.nom}
                            >
                                <DropDownList
                                    defaultItem={null}
                                    ariaLabelledBy={"resourceLabel"}
                                    data={allResources}
                                    textField={"nom"}
                                    dataItemKey={"id"}
                                    value={resourceFilter.resource}
                                    onChange={handleEquEqu} />
                            </FloatingLabel>
                        </div>
                    </Popup>
                </div>
                <span className='title'>{title}</span>
                <div className='popup-schedule-caption'>
                    <button className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base caption-help" onClick={handleClick} ref={anchor}>
                        <i className="icon-help" />
                    </button>
                    <Popup anchor={anchor.current} className="popup-real" show={show} popupClass={"popup-content"}>
                        <span style={{ fontWeight: 800, fontSize: "18px" }}>Légende des plannings :</span>
                        <div className='popup-scheduler-caption-content'>
                            <div className='popup-scheduler-caption-column'>
                                <span style={{ fontWeight: 800 }}>Disponibilité</span>
                                <div className='popup-scheduler-caption-item'>
                                    <div className="caption-color" style={{ backgroundColor: "#FFFFFF", border: "1px solid #D1D1D1" }}></div><span>Libre</span>
                                </div>
                                <div className='popup-scheduler-caption-item'>
                                    <div className="caption-color" style={{ backgroundColor: "#8fa6e0" }}></div><span>Réservé</span>
                                </div>
                                <div className='popup-scheduler-caption-item'>
                                    <div className="caption-color" style={{ backgroundColor: "#F9BF91" }}></div><span>Occupé</span>
                                </div>
                                <div className='popup-scheduler-caption-item'>
                                    <div className="caption-color" style={{ backgroundColor: "#D1D1D1" }}></div><span>Indisponible</span>
                                </div>
                            </div>
                        </div>
                    </Popup>
                </div>
                <div className='spacer'></div>
                <Button onClick={handlePrevDate} className="scheduler-arrow-button">
                    <i className='icon-arrow-left-medium'></i>
                </Button>
                <span className='scheduler-dates'>{
                    datesStart?.toLocaleDateString("fr", { month: "long", day: 'numeric', year: 'numeric' })
                    + " - "
                    + datesEnd?.toLocaleDateString("fr", { month: "long", day: 'numeric', year: 'numeric' })
                }</span>
                <Button onClick={handleNextDate} className="scheduler-arrow-button">
                    <i className='icon-arrow-right-medium'></i>
                </Button>
                <div className='spacer'></div>
                <Button onClick={handleSetToday} fillMode="outline">Aujourd'hui</Button>
                {user.role !== 'IC' ?
                    width < 1400 ?
                        <Button onClick={exportPDFWithComponent} fillMode="solid" themeColor="primary" className='icon-button'> <i className='icon-download'></i> </Button >
                        : <Button onClick={exportPDFWithComponent} fillMode="solid" themeColor="primary">Exporter </Button >
                    : null
                }
            </div >
        )
    }

    function handleSetToday() {
        const dateStart = moment().startOf('day').day(1)
        const dateEnd = moment().startOf('day').day(7)
        setPeriod({ startDate: dateStart.toDate(), endDate: dateEnd.toDate() })
    }

    function handlePrevDate() {
        const dateStart = moment(period?.startDate).startOf('day').subtract(7, 'days');
        const dateEnd = moment(period?.endDate).startOf('day').subtract(7, 'days');
        setPeriod({ startDate: dateStart.toDate(), endDate: dateEnd.toDate() })
    }

    function handleNextDate() {
        const dateStart = moment(period?.startDate).startOf('day').add(7, 'days');
        const dateEnd = moment(period?.endDate).startOf('day').add(7, 'days');
        setPeriod({ startDate: dateStart.toDate(), endDate: dateEnd.toDate() })
    }


    useEffect(() => {
        async function init() {
            let res: ResourceDTO[] = [];
            res = await resourceService.getAllResources(null,"type-asc,nom-asc").catch((error: any) => notify({ message: error, type: "error" }))

            const modalities: ModalityDTO[] = props.user.modalites;
            console.log(props.user);

            if ((props.user.role === "IC" || props.user.role === "BLM") && modalities) {
                res = res.filter((resource: ResourceDTO) => { return modalities.find((res) => resource.modalite.id === res.id) })
            }
            const dateStart = moment().day(1)
            const dateEnd = moment().day(7)
            const initPeriod = {
                startDate: dateStart.toDate(),
                endDate: dateEnd.toDate(),
            }
            setPeriod(initPeriod)
            
            setAllResources(res)
            //setSelectedResources(getSelectedResources(res, resourceFilter))
        }
        init();
    }, [props.user.modalities, resourceService]);


    function handleDateChange(event: any) {
        setPeriod(event.value)
    }

    function backExport() {
        setRenderExport(false)
    }

    const exportPDFWithComponent = () => {
        setRenderExport(!renderExport);
    };


    return (
        <>
            <ResourceHeader />
            <div id='scheduler-div'>
                <Scheduler
                    height={407}
                    data={transformOccupationsToItems(occupations)}
                    date={period.startDate ? period.startDate : undefined}
                    group={{
                        resources: [title],
                        orientation: "vertical"
                    }}
                    resources={[
                        {
                            name: title,
                            data: selectedResources.map((item: ResourceDTO) => {
                                return {
                                    value: item.id, text: (<div className='header-item'>{item.nom}</div>)
                                }
                            }),
                            field: "resourceId",
                            valueField: "value",
                            textField: "text",

                        }
                    ]}
                    header={() => null}
                    footer={() => null}
                    viewItem={CustomViewItem}
                    item={CustomResourceItem}
                    onDateChange={handleDateChange}
                    slot={CustomSlot}
                >
                    <WeekView
                        dateHeaderCell={CustomDateHeader}
                        slotDivisions={1}
                        slotDuration={1440}
                        step={7}


                    ></WeekView>
                </Scheduler>
            </div>
            {renderExport ?
                <ExportedScheduler
                    type={type}
                    resources={selectedResources}
                    filters={[resourceFilter, occupationFilter]}
                    back={backExport}
                    fileName={`Planning ${title} ${moment(period?.startDate).format("DD_MM_YYYY")}`}
                    date={period?.startDate}
                />
                : null
            }
        </>
    );
}

export default connect(mapStateToProps)(ResourceSchedulePanel);



