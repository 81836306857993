import axios from 'utils/axios';
import { ModalityDTO } from '../model/ModalityDTO';

export class ModalityService {
    getAll() {
        return axios.get(`/api/v1/modalites`)
            .then(response => {
                const modality: ModalityDTO[] = response.data;
                return modality;
            })
            .catch(error => {
                console.log(error)

            });
    }
    post(data: ModalityDTO) {
        return axios.post(`/api/v1/modalites`, data)
            .catch((error: any) => {
                console.log(error)

            });
    }
    get(id: number) {
        return axios.get(`/api/v1/modalites/` + id)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error)

            });
    }
    put(data: ModalityDTO) {
        return axios.put(`/api/v1/modalites/` + data.id, data)
            .catch((error: any) => {
                console.log(error)

            });
    }
    delete(id: number) {
        return axios.delete(`/api/v1/modalites/` + id)
            .catch((error: any) => {
                console.log(error)

            });
    }
} 