import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ReviewRequestService } from "services/ReviewRequestService";
import { Form, Field, FormElement, FormRenderProps, FieldWrapper, } from "@progress/kendo-react-form";

import moment from "moment";

import ReviewRequest, { GetReviewRequestDTO, GetToPostReview } from 'model/ReviewRequestDTO';
import { equipmentsValidator, requiredValidator } from 'utils/FormValidators';
import { Button } from '@progress/kendo-react-buttons';
import { Card, StackLayout } from "@progress/kendo-react-layout";
import FieldDateTimePicker from "components/CustomFormFields/FieldDateTimePicker";
import { FieldEquipmentReview } from "components/CustomFormFields/FieldListBox";
import { Spinner } from "components/Spinner/Spinner";
import { RootState } from "store";
import { connect } from "react-redux";
import FieldTextArea from "components/CustomFormFields/FieldTextArea";
import { useNotification } from "components/Notifications/NotificationProvider";
import ResourceErrorPopup from "components/CustomPopups/ResourceErrorPopup";

const NAVIGATE_PATH = "/demandes/maintenance"

const ReviewRequestFormView = (props: any) => {

    const requestFieldFormat = {
        comment: "",
        startDate: new Date(),
        endDate: new Date(),
        equipments: [],
        finalite: null,
        demandeId: 0,
    }

    const navigate = useNavigate();
    const notify = useNotification();
    let { id } = useParams();
    const [request, setRequest] = useState<GetReviewRequestDTO>(new ReviewRequest());
    const [init, setInit] = useState(requestFieldFormat);

    const today = new Date();
    const [dates, setDates] = useState({
        startDate: today,
        endDate: today
    });

    useEffect(() => {
        switch (props.user.role) {
            case "CS":
            case "GL":
            case "BLM":
                break;
            default:
                navigate("/")
                break;
        }
    }, [props.user, navigate])

    useEffect(() => {
        function handleInit(): any {

            if (props.layout === "edit" && id) {
                const requestService = new ReviewRequestService();
                const demandeId = +id;
                requestService.get(demandeId).then((data: any) => {
                    if (data) {
                        console.log(data);
                        data.finalite = null;
                        data.demandeId = demandeId;
                        setRequest(data);
                        setDates({
                            startDate: new Date(data.dateDebut),
                            endDate: new Date(data.dateFin),
                        })
                        const res = {
                            comment: data.commentaire,
                            startDate: data.dateDebut,
                            endDate: data.dateFin,
                            equipments: data.equipements,
                            finalite: null,
                            demandeId,
                        }
                        setInit(res)
                    }
                })
                    .catch((error) => notify({ message: error, type: "error" }))

            }
        }
        handleInit();
    }, [id, props.layout])

    const handleStartDateChange = (event: any) => {
        setDates(({ ...dates }) => ({ ...dates, startDate: event.value }));
        setRequest(({ ...request }) => ({ ...request, dateDebut: moment(event.value).format('YYYY-MM-DDTHH:mm:ss') }));
    }
    const handleEndDateChange = (event: any) => {
        setDates(({ ...dates }) => ({ ...dates, endDate: event.value }));
        setRequest(({ ...request }) => ({ ...request, dateFin: moment(event.value).format('YYYY-MM-DDTHH:mm:ss') }));
    }

    const handleSubmit = (dataItem: { [name: string]: any; }) => {

        let result = request;
        result.commentaire = dataItem.comment;
        result.equipements = dataItem.equipments;
        result.dateDebut = moment(dataItem.startDate).format('YYYY-MM-DDTHH:mm:ss');
        result.dateFin = moment(dataItem.endDate).format('YYYY-MM-DDTHH:mm:ss');

        const requestService = new ReviewRequestService();
        if (props.layout === "edit" && id) {
            requestService.update(+id, GetToPostReview(result)).then(() => {
                notify({ message: "Modification terminée", type: "success" })
                navigate(NAVIGATE_PATH)
            }
            ).catch((error) => {
                if (error.request && error.request.status === 409) {
                    prepareBookedError(error, "modification")
                    togglePopupError()
                } else
                    notify({ message: error, type: "error" })
            });

        } else {
            requestService.create(GetToPostReview(result))
                .then(() => {
                    notify({ message: "Ajout terminé", type: "success" })
                    navigate(NAVIGATE_PATH)

                }).catch((error) => {
                    if (error.request && error.request.status === 409) {
                        prepareBookedError(error, "ajout")
                        togglePopupError()
                    } else
                        notify({ message: error, type: "error" })
                });
        }
    };


    /*const generalUrl = "/demandes/maintenance"

    useEffect(() => {
        if (props.layout === "edit") {
            switch (props.user.role) {
                case "CS":
                    break;
                case "GL":
                    break;
                default:
                    navigate(generalUrl)
                    break;
            }
        } else if (props.layout === "create") {
            switch (props.user.role) {
                case "CS":
                    break;
                default:
                    navigate(generalUrl)
                    break;
            }
        } else {
            navigate(generalUrl)
        }
    }, [props.user, request])*/

    const startDateValidator = (value: any) =>
        !value
            ? "Précisez une date de début"
            : dates.startDate < today && props.user.role !== 'GL'
                ? "La date ne doit pas être dans le passé"
                : ""

    const endDateValidator = (value: any) =>
        !value
            ? "Précisez une date de fin"
            : dates.endDate < today && props.user.role !== 'GL'
                ? "La date ne doit pas être dans le passé"
                : dates.endDate < dates.startDate
                    ? "la date de fin ne peut pas être avant la date de début"
                    : ""


    const buttonGroup = (formRenderProps: any) => {
        return (
            <>
                <Button
                    name="cancel"
                    type="button"
                    fillMode="outline"
                    onClick={() => { navigate(NAVIGATE_PATH) }}
                >
                    Retour
                </Button>
                {(props.user.role === "BLM")
                    ? null
                    : <Button
                        name="submit"
                        type={"submit"}
                        disabled={!formRenderProps.allowSubmit}
                        themeColor={"primary"}
                    >
                        Enregistrer
                    </Button>
                }
            </>

        )
    }


    const [visible, setVisible] = useState(false)
    const [bookedError, setBookedError] = useState<any[]>([])
    const [errorType, setErrorType] = useState("")

    function prepareBookedError(error: any, type: string) {
        let result: any[] = []
        if (error.request && error.request.response) {
            try {
                const resultJSON = JSON.parse(error.request.response)
                for (const error of resultJSON.errors) {
                    result.push(error)
                }
                setBookedError(result)
                if (type === "modification")
                    setErrorType("de modifier")
                else if (type === "ajout")
                    setErrorType("d'ajouter")
                else if (type === "validation")
                    setErrorType("de valider")
            } catch (error) {
                notify({ message: error, type: "error" })
            }
        }
    }

    function togglePopupError() {
        setVisible(!visible)
    }


    const anchor = useRef<HTMLButtonElement | null>(null);

    return (
        <>
            <ResourceErrorPopup anchor={anchor.current} togglePopupError={togglePopupError} bookedError={bookedError} type={errorType} visible={visible} />
            <Form
                initialValues={init}
                key={JSON.stringify(init)}
                onSubmit={handleSubmit}
                render={(formRenderProps: FormRenderProps) => (
                    <div className='wrapper'>
                        <FormElement>

                            <div className="row" style={{ justifyContent: "space-between", alignItems: "center" }}>
                                <span className="section-title">{props.layout === "edit" ? "Demande de Maintenance n°" + request.numero : "Nouvelle demande de Maintenance"}</span>
                                <div className="k-form-buttons"
                                    style={{ marginTop: "0" }}
                                >
                                    {buttonGroup(formRenderProps)}
                                </div>
                            </div>
                            <Card>
                                <div>

                                    <div className='form-section'>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}
                                        >
                                            <Field
                                                name={"startDate"}
                                                label={"Date et Heure de Début"}
                                                required={true}
                                                min={today}
                                                date={dates.startDate}
                                                component={FieldDateTimePicker}
                                                onChange={handleStartDateChange}
                                                validator={startDateValidator}

                                            />
                                            <Field
                                                name={"endDate"}
                                                label={"Date et Heure de Fin"}
                                                required={true}
                                                min={dates.startDate}
                                                date={dates.endDate}
                                                component={FieldDateTimePicker}
                                                onChange={handleEndDateChange}
                                                validator={endDateValidator}
                                            />
                                        </StackLayout>

                                        <Field
                                            name={"equipments"}
                                            label={"Equipements"}
                                            required={true}
                                            request={request}
                                            layout={props.layout}
                                            modalites={props.user.modalites}
                                            component={FieldEquipmentReview}
                                            validator={equipmentsValidator}
                                        />

                                        <FieldWrapper>
                                            <legend className={"k-form-legend"}>Commentaire <span style={{color:"red"}}>*</span></legend>
                                            <Field
                                                name={"comment"}
                                                label={"Commentaire"}
                                                value={request.commentaire ? request.commentaire : ""}
                                                rows={4}
                                                component={FieldTextArea}
                                                required={true}
                                                validator={requiredValidator}
                                            />
                                        </FieldWrapper>
                                    </div>

                                    <div className="k-form-buttons">
                                        {buttonGroup(formRenderProps)}
                                    </div>

                                </div>
                            </Card>
                        </FormElement>
                    </div>

                )
                }

            />
            {
                props.loading
                    ? <Spinner />
                    : null
            }
        </>
    )
}
const mapStateToProps = function (state: RootState) {
    return {
        user: state.user,
        loading: state.loading
    }
}
export default connect(mapStateToProps)(ReviewRequestFormView);