import { Button } from "@progress/kendo-react-buttons";
import { Card } from "@progress/kendo-react-layout";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import {
    Grid,
    GridCellProps,
    GridColumn as Column,
    GridDataStateChangeEvent,
    GridSortChangeEvent,
} from "@progress/kendo-react-grid";

import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { connect } from "react-redux";
import { RootState } from "store";
import { useNotification } from "components/Notifications/NotificationProvider";
import IAFormView from "./IAFormView";
import { DeleteButton, EditButton } from "components/CustomCells/ActionCell";
import { IA, IADTO } from "model/IADTO";
import { IAService } from "services/IAService";
import { ActifCell } from "components/CustomCells/StatusCell";
import IAErrorPopup from "components/CustomPopups/IAErrorPopup";

const IAGridView = (props: any) => {
    const onEdit = (item: IADTO) => {
        setEditedItem(item);
        setEditVisible(true);
    };
    const ActionsCell = (props: GridCellProps) => (
        <td>
            <EditButton {...props} action={onEdit} />
            <DeleteButton {...props} action={deleteItem} message={`Êtes-vous sûr de vouloir supprimer l'IA ${props.dataItem.nomPrenom} ?`} />
        </td>
    );
    const navigate = useNavigate();
    const notify = useNotification();
    const initialSort: SortDescriptor[] = [
        {
            field: "nomPrenom",
            dir: "asc",
        },
    ];

    const [editVisible, setEditVisible] = useState<boolean>(false);
    const [editedItem, setEditedItem] = useState<IADTO>(new IA());
    const initialPageState = {
        skip: 0,
        take: 10,
        sort: initialSort,
        buttonCount: 10,
        info: true,
        pageSizes: true,
        previousNext: true,
        responsive: true,
    };
    const [pageState, setPageState] = useState(initialPageState);
    let { skip, take, sort } = pageState;

    const [title, setTitle] = useState("");
    const [addTitle, setAddTitle] = useState("");
    const initialClassesState = {
        content: [],
        pageNumber: 1,
        pageSize: 10,
        totalElements: 0,
        totalPages: 1
    };
    const [items, setItems] = useState(initialClassesState)

    useEffect(() => {
        if (!(props.user && props.user.role === "GL")) {
            navigate("/")
        }
    }, [props.user, navigate])

    const getItems = useCallback((pageSize: number | null = null, itemOffset: number | null = null, sortDesciptor: SortDescriptor[] | null = null) => {
        const sortedBy = sortDesciptor?.map((item: SortDescriptor) => item.field + "-" + item.dir).join(",")
        const iAService = new IAService()
        iAService.getAll(pageSize, itemOffset, sortedBy)
            .then(result => {
                setItems(result)
            });
    }, [])


    useEffect(() => {
        const { skip, take, sort } = pageState;
        getItems(take, skip, sort)
    }, [pageState, getItems])

    useEffect((() => {
        setTitle("Gestion des IA")
        setAddTitle("Ajouter un IA")
    }), [])

    async function saveItem(item: IADTO) {
        const iAService = new IAService();
        if (item.id === 0) {
            iAService.post(item).then(() => {
                setEditVisible(false);
                getItems(take, skip, sort)
            })
                .catch((error: any) => {
                    notify({ message: error, type: "error" })
                });
        } else {
            iAService.put(item.id, item)
                .then(() => {
                    setEditVisible(false);
                    getItems(take, skip, sort)
                })
                .catch((error: any) => {
                    if (error.request && error.request.status === 409) {
                        let result: any[] = []
                        if (error.request && error.request.response) {
                            try {
                                const resultJSON = JSON.parse(error.request.response)

                                for (const error of resultJSON.errors) {
                                    result.push(error)
                                }
                                setBookedError(result)

                            } catch (error) {
                                notify({ message: error, type: "error" })
                            }
                        }
                        togglePopupError()
                    } else
                        notify({ message: error, type: "error" })
                });
        }
    }


    const [visible, setVisible] = useState(false)
    const [bookedError, setBookedError] = useState<any[]>([])

    async function deleteItem(item: IADTO) {
        const iAService = new IAService();
        await iAService.delete(item.id)
            .then(() => getItems(take, skip, sort))
            .catch((error: any) => {
                if (error.request && error.request.status === 409) {
                    let result: any[] = []
                    if (error.request && error.request.response) {
                        try {
                            const resultJSON = JSON.parse(error.request.response)

                            for (const error of resultJSON.errors) {
                                result.push(error)
                            }
                            setBookedError(result)

                        } catch (error) {
                            notify({ message: error, type: "error" })
                        }
                    }
                    togglePopupError()
                } else
                    notify({ message: error, type: "error" })
            });
    }

    function togglePopupError() {
        setVisible(!visible)
    }

    const anchor = useRef<HTMLButtonElement | null>(null);


    const onPageChange = (event: GridDataStateChangeEvent) => {
        const { skip, take } = event.dataState;
        setPageState({ ...pageState, skip: skip!, take: take! });

    };

    const onSortChange = (event: GridSortChangeEvent) => {
        const sort = event.sort
        setPageState({ ...pageState, sort: sort });
    }

    function onNewItem() {
        setEditVisible(true)
        setEditedItem(new IA());
    }

    return (
        <div className='wrapper'>
            {editVisible && (
                <IAFormView
                    onCancel={() => setEditVisible(false)}
                    onSave={saveItem}
                    item={editedItem}
                />
            )}
            <IAErrorPopup anchor={anchor.current} togglePopupError={togglePopupError} bookedError={bookedError} visible={visible} />
            <div className="row" style={{ justifyContent: "space-between", alignItems: "center" }}>
                <span className="section-title">{title}</span>

                <Button
                    themeColor={"primary"}
                    onClick={onNewItem}
                >
                    {addTitle}
                </Button>
            </div>
            <Card>
                {items.content.length
                    ?
                    (<><Grid
                        data={orderBy(items.content, sort)}

                        sortable={true}
                        sort={sort}
                        onSortChange={onSortChange}
                        skip={skip}
                        take={take}
                        onDataStateChange={onPageChange}
                        pageable={true}
                        total={items.totalElements}
                        pageSize={10}
                    >
                        <Column key="nam" title="Nom" field="nomPrenom" />,
                        <Column key="nam" title="Email" field="email" />,
                        <Column key="atv" title="Statut" field="actif" cell={ActifCell} />,
                        <Column key="act" title="Actions" width={110} cell={ActionsCell} />
                    </Grid>
                    </>)
                    :
                    <div>Aucuns IA trouvés</div>
                }
            </Card>
        </div >
    )
}
const mapStateToProps = function (state: RootState) {
    return {
        user: state.user,
        loading: state.loading,
        isLoggedIn: state.isLoggedIn,
    }
}
export default (connect(mapStateToProps)(IAGridView));