import moment from "moment"
import { Modality, ModalityDTO } from "./ModalityDTO"
import { EquipmentDTO } from "./EquipmentDTO"
import { Request, RequestDTO } from "./RequestDTO"
import { Carrier, CarrierDTO } from "./CarrierDTO"
import { ConstraintDTO } from "./ConstraintDTO"
import { IA, IADTO } from "./IADTO"

export interface GetDemoRequestDTO extends RequestDTO {
  dateDeRetrait: string,
  equipements: EquipmentDTO[],
  modalite: ModalityDTO,
  adresseDeLivraison: {
    rue: string,
    codePostal: string,
    ville: string
  },
  contrainteDeLivraison: ConstraintDTO | null,
  dateDeLivraison: string,
  client: string,
  contact: {
    nom: string,
    service: string,
    fonction: string,
    telephone: string
  },
  transporteur: CarrierDTO,
  qualification: number,
  prestation: {
    dateDeDebut: string,
    dateDeFin: string
  } | null,
  ia: IADTO,
}

export interface PostDemoRequestDTO {
  numero: string,
  commentaire: string,
  modaliteId: number,
  adresseDeLivraison: {
    rue: string,
    codePostal: string,
    ville: string,
  },
  contrainteDeLivraisonId: number | null,
  dateDeLivraison: string,
  client: string,
  contact: {
    nom: string,
    service: string,
    fonction: string,
    telephone: string,
  },
  qualification: number,
  transporteurId: number,
  prestation: {
    dateDeDebut: string,
    dateDeFin: string,
  } | null,
  dateDeRetrait: string,
  equipementIds: number[],
  iaId: number,
}

export function GetToPostDemo(data: GetDemoRequestDTO): PostDemoRequestDTO {
  console.log(data);

  const format: PostDemoRequestDTO = {
    numero: data.numero,
    commentaire: data.commentaire,
    modaliteId: data.modalite.id,
    adresseDeLivraison: {
      rue: data.adresseDeLivraison.rue,
      codePostal: data.adresseDeLivraison.codePostal,
      ville: data.adresseDeLivraison.ville
    },
    contrainteDeLivraisonId: data.contrainteDeLivraison?.id || null,
    dateDeLivraison: data.dateDeLivraison,
    client: data.client,
    contact: {
      nom: data.contact.nom,
      service: data.contact.service,
      fonction: data.contact.fonction,
      telephone: data.contact.telephone
    },
    transporteurId: data.transporteur.id,
    prestation: data.prestation ? {
      dateDeDebut: data.prestation.dateDeDebut,
      dateDeFin: data.prestation.dateDeFin
    } : null,
    qualification: data.qualification,
    dateDeRetrait: data.dateDeRetrait,
    equipementIds: data.equipements.map((item: any) => item.id),
    iaId: data.ia.id,
  }
  return format;
}

export default class DemoRequest extends Request {
  dateDeRetrait: string = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
  equipements = []
  modalite = new Modality()
  adresseDeLivraison: { rue: string; codePostal: string; ville: string } = {
    rue: "",
    codePostal: "",
    ville: ""
  }
  contrainteDeLivraison = null
  dateDeLivraison: string = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
  client: string = ""
  contact: { nom: string; service: string; fonction: string; telephone: string } = {
    nom: "",
    service: "",
    fonction: "",
    telephone: ""
  }
  transporteur = new Carrier()
  prestation: { dateDeDebut: string; dateDeFin: string } | null = {
    dateDeDebut: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
    dateDeFin: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss')
  }
  intervenants = []
  finalite = 0

  ia = new IA();
  constructor() {
    super();
    this.type = 1;
  }

}