export const modalitiesByType = {
    demo: [
        { nom: "US", type: 0 }
    ],
    loan: [
        { nom: "US", type: 0 }
    ]
}

export const RequestTypes = [
    {
        name: "Démonstration",
        id: 0
    },
    {
        name: "Prêt",
        id: 1
    }
    
]