import { DocumentDTO } from "./DocumentDTO";
import { Qualification } from "./Qualification";
import { UserDTO } from "./UserDTO";

export interface RequestDTO {
    type: number,
    qualification: Qualification,
    numero: string,
    commentaire: string,
    statut: number,
    id: number,
    dateCreation: string,
    demandeDocuments: DocumentDTO[]
    user?: UserDTO
}

export interface IRequest extends RequestDTO{
}

export abstract class Request implements IRequest{
    type: number;
    qualification: Qualification;
    numero: string;
    commentaire: string;
    statut: number;
    id: number;
    dateCreation: string
    demandeDocuments: DocumentDTO[]

    constructor(){
        this.type=0;
        this.qualification=Qualification.Autres;
        this.numero="";
        this.commentaire="";
        this.statut=0;
        this.id=0;
        this.dateCreation="";
        this.demandeDocuments=[];
    }
    
    
}