
const phoneRegex = new RegExp(/^(([(]?([+]|[0]{2}[. -]?)[3]{2}[)]?)|0)[. -]?\d[. -]?\d{2}[. -]?\d{2}[. -]?\d{2}[. -]?\d{2}$/)
const emailRegex = new RegExp(/^[\w._%+-]+@[\w.-]+\.[\w]{2,4}$/)
const postalCodeRegex = new RegExp(/^\d{5}$/)

const orderNumberRegex = new RegExp(/^[\w\d-]+$/)


//@TODO: A revoir et valider YGR
export const requiredValidator = (value: any) =>
    value !== null && value !== undefined && value !== "" ? "" : "Ce champs est requis.";
export const radioGroupValidator = (value: any) =>
    value !== null && value !== undefined ? "" : "Ce champs est requis.";

export const phoneValidator = (value: any) =>
    !value
        ? "Renseignez un numéro de téléphone"
        : !phoneRegex.test(value)
            ? "le numéro saisi n'est pas valide"
            : ""
export const emailValidator = (value: any) =>
    !value
        ? "Renseignez un mail"
        : !emailRegex.test(value)
            ? "le mail saisi n'est pas valide"
            : ""

export const postalCodeValidator = (value: any) =>
    !value
        ? "Renseignez un code postal"
        : !postalCodeRegex.test(value)
            ? "le code postal saisi n'est pas valide"
            : ""

export const orderNumberValidator = (value: any) =>
    !value
        ? "Renseignez un numéro de commande"
        : !orderNumberRegex.test(value)
            ? "le numéro saisi n'est pas valide"
            : ""

export const equipmentsValidator = (value: any) =>
    value.length === 0
        ? "Selectionnez au moins un équipement"
        : value.length > 3
            ? "Impossible de séléctionner plus de 3 équipements"
            : ""

export const formationBudgetValidator = (value: any) =>
    value === null || value === undefined
        ? "Précisez un nombre de jours"
        : value < 0
            ? "La valeur ne peut pas être négative"
            : ""

export const serialNumberValidator = (value: any) =>
    !value
        ? "Veuillez renseigner un numéro de série"
        : value.trim().length > 10
            ? "Le numéro de série ne peut pas contenir plus de 10 caractères"
            : ""
export const commandNumberValidator = (value: any) =>
    !value
        ? ""
        : value.trim().length > 10
            ? "Le numéro de commande ne peut pas contenir plus de 10 caractères"
            : ""