import { PostResourceDTO } from 'model/ResourceDTO';

export abstract class ResourceService {
    abstract getAll(pageSize: any, itemOffset: any, sortedBy: any) : Promise<any>
    
    abstract getAllResources(filerBy: any)  : Promise<any>
    
    abstract post(data: PostResourceDTO)  : Promise<any>
    
    abstract get(id: number) : Promise<any>
    
    abstract patch(id: number, data: PostResourceDTO) : Promise<any>
    
    abstract put(id: number, data: PostResourceDTO) : Promise<any> 
    
    abstract delete(id: number) : Promise<any>

    abstract getType() : string

    abstract getTypeLabel() : string[]


} 