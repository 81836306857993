import axios from 'utils/axios';
import { MAX_PAGE_SIZE } from 'services/Constantes';
import { Equipment, PostEquipmentDTO } from '../model/EquipmentDTO';
import { ResourceService } from './ResourceService';

export class EquipmentService extends ResourceService {
    getAll(pageSize: any, itemOffset: any, sortedBy: any) {
        if (!pageSize) {
            pageSize = 10
        }
        if (!itemOffset) {
            itemOffset = 0
        }
        if (!sortedBy) {
            sortedBy = "dateCreation-asc"
        }
        return axios.get(`/api/v1/equipements?PageOffset=` + itemOffset + `&PageSize=` + pageSize + `&SortedBy=` + sortedBy)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error)

            });
    }
    getAllResources(filteredBy: any = null, sortedBy: any = null) {
        let filtered = "&FilteredBy="
        if (filteredBy) {
            filtered += filteredBy
        } else {
            filtered = ""
        }
        let sorted = "&SortedBy="
        if (sortedBy) {
            sorted += sortedBy
        } else {
            sorted = ""
        }
        return axios.get(`/api/v1/equipements/Active?PageSize=${MAX_PAGE_SIZE}&MaxPageSize=${MAX_PAGE_SIZE}${filtered}${sorted}`)
            .then(response => {
                return response.data.content.map((d: any) => new Equipment(
                    d.nom, d.type, d.modalite, d.numeroDeSerie, d.numeroDeCommande, d.dateDeSortieUsine, d.actif, d.id));
            })
            .catch(error => {
                console.log(error)

            });
    }
    post(data: PostEquipmentDTO) {
        return axios.post(`/api/v1/equipements`, data)
            .catch((error: any) => {
                console.log(error)
                throw error
            });
    }
    get(id: number) {
        return axios.get(`/api/v1/equipements/` + id)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error)

            });
    }
    getAvailables(startDate: string, endDate: string,type: number|null=null) {
        return axios.get(`/api/v1/equipements/GetAvailables`, { params: { dateDebut: startDate, dateFin: endDate, typeEquipement: type, modaliteIds: [1] } })
            .then(response => {
                return response.data;

            })
            .catch(error => {
                console.log(error)

            });
    }
    patch(id: number, data: PostEquipmentDTO) {
        return axios.patch(`/api/v1/equipements/` + id, data)
            .catch((error: any) => {
                console.log(error)

            });
    }
    put(id: number, data: PostEquipmentDTO) {
        return axios.put(`/api/v1/equipements/` + id, data)
            .catch((error: any) => {
                console.log(error)
                throw error
            });
    }
    delete(id: number) {
        return axios.delete(`/api/v1/equipements/` + id)
            .catch((error: any) => {
                console.log(error)
                throw error
            });
    }

    getType(): string {
        return "equipment"
    }

    getTypeLabel(): string[] {
        return ["Équipement", "Équipements"]
    }
} 