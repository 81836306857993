import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Form, Field, FormElement, FormRenderProps } from "@progress/kendo-react-form";

import { Button } from '@progress/kendo-react-buttons';
import { Card, StackLayout } from "@progress/kendo-react-layout";
import { Spinner } from "components/Spinner/Spinner";
import { ModalityDTO } from "model/ModalityDTO";
import { GetToPostUser, User, UserDTO } from "model/UserDTO";
import AuthService from "services/AuthService";
import FieldInput from "components/CustomFormFields/FieldInput";
import FieldComboBox from "components/CustomFormFields/FieldComboBox";
import { RoleService } from "services/RoleService";
import { RoleDTO } from "model/RoleDTO";
import FieldMultiSelect from "components/CustomFormFields/FieldMultiSelect";
import { ModalityService } from "services/ModalityService";
import FieldCheckBox from "components/CustomFormFields/FieldCheckBox";
import { emailValidator, requiredValidator } from "utils/FormValidators";
import { useNotification } from "components/Notifications/NotificationProvider";
import { RootState } from "store";
import { connect } from "react-redux";

const NAVIGATE_PATH = "/admin/utilisateurs"

const UserFormView = (props: any) => {

    const userFieldFormat = {
        firstName: "",
        lastName: "",
        userName: "",
        password: "admin",
        email: "",
        role: null,
        actif: true,
        modalities: null
    }


    const navigate = useNavigate();
    let { id } = useParams();
    const [user, setUser] = useState<UserDTO>(new User());
    const [init, setInit] = useState<{
        firstName: string,
        lastName: string,
        userName: string,
        password: string,
        email: string,
        role: string | null,
        actif: boolean,
        modalities: ModalityDTO[] | null
    }>(userFieldFormat);
    const [roles, setRoles] = useState<RoleDTO[]>([]);
    const [modalities, setModalities] = useState<ModalityDTO[]>([]);

    const notify = useNotification();

    useEffect(() => {
        function handleInit(): any {
            function fetchRoles() {
                const roleService = new RoleService();
                roleService.getAll().then((result: any) => {
                    setRoles(result)
                })
            }
            function fetchModality() {
                const modalityService = new ModalityService();
                modalityService.getAll().then((result: any) => {
                    setModalities(result)
                })
            }

            if (props.layout === "edit" && id) {
                AuthService.getUser(+id).then((data: UserDTO) => {
                    if (data) {
                        console.log(data);

                        setUser(data);
                        const res = {
                            firstName: data.firstName,
                            lastName: data.lastName,
                            userName: data.userName,
                            password: data.password, //@TODO précédemment init.password c'était voulu?
                            email: data.email,
                            role: data.role,
                            actif: data.actif,
                            modalities: data.modalites
                        }
                        console.log(res);

                        setInit(res)
                    }
                });
            }

            fetchRoles()
            fetchModality()
        }
        handleInit();
    }, [id, props.layout])

    const handleSubmit = (dataItem: { [name: string]: any; }) => {

        let result = user;
        result.firstName = dataItem.firstName
        result.lastName = dataItem.lastName
        result.role = dataItem.role
        result.actif = dataItem.actif
        result.modalites = dataItem.modalities
        result.password = dataItem.password
        result.email = dataItem.email
        result.userName = dataItem.userName

        console.log(result);

        if (props.layout === "edit" && id) {
            AuthService.putUser(+id, GetToPostUser(result)).then((response) => {
                if (response)
                    navigate(NAVIGATE_PATH)
            }
            ).catch((error) => {
                notify({ message: error, type: "error" })
            });
        } else {

            AuthService.postUser(GetToPostUser(result))
                .then(() => {
                    navigate(NAVIGATE_PATH)

                }).catch((error: any) => {
                    if (error.request && error.request.message) { notify({ message: error.request.message, type: "error" }) } else { notify({ message: error.message, type: "error" }) }
                });
        }
    };

    const buttonGroup = (formRenderProps: any) => {
        return (
            <>
                <Button
                    name="cancel"
                    type="button"
                    fillMode="outline"
                    onClick={() => { navigate(NAVIGATE_PATH) }}
                >
                    Annuler
                </Button>
                <Button
                    name="submit"
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                    themeColor={"primary"}
                >
                    Enregistrer
                </Button>
            </>

        )
    }

    return (
        <>
            <Form
                initialValues={init}
                key={JSON.stringify(init)}
                onSubmit={handleSubmit}
                render={(formRenderProps: FormRenderProps) => (
                    <div className='wrapper'>
                        <FormElement>

                            <div className="row" style={{ justifyContent: "space-between", alignItems: "center", height: "40px" }}>
                                <span className="section-title">{props.layout === "edit" ? "Utilisateur " + user.firstName + " " + user.lastName : "Ajouter un utilisateur"}</span>
                            </div>
                            <Card>
                                <div>

                                    <div className='form-section'>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}
                                        >
                                            <Field
                                                name="actif"
                                                label={"Utilisateur actif"}
                                                component={FieldCheckBox}
                                            />
                                        </StackLayout>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}
                                        >
                                            <Field
                                                name={"userName"}
                                                label={"GID"}
                                                required={true}
                                                component={FieldInput}
                                                validator={requiredValidator}
                                            />
                                            <Field
                                                name={"email"}
                                                label={"Email"}
                                                required={true}
                                                component={FieldInput}
                                                validator={emailValidator}
                                            />
                                        </StackLayout>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}
                                        >
                                            <Field
                                                name={"firstName"}
                                                label={"Prénom"}
                                                required={true}
                                                component={FieldInput}
                                                validator={requiredValidator}
                                            />
                                            <Field
                                                name={"lastName"}
                                                label={"Nom"}
                                                required={true}
                                                component={FieldInput}
                                                validator={requiredValidator}
                                            />
                                        </StackLayout>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}
                                        >
                                            <Field
                                                name={"role"}
                                                label={"Rôle"}
                                                required={true}
                                                id={"modalities"}
                                                data={roles.map((item: any) => item.name)}
                                                component={FieldComboBox}
                                                validator={requiredValidator}
                                            />
                                            <Field
                                                name={"modalities"}
                                                label={"Modalités"}
                                                data={modalities}
                                                textField="nom"
                                                dataItemKey="id"
                                                required={true}
                                                component={FieldMultiSelect}
                                                validator={requiredValidator}
                                            />
                                        </StackLayout>
                                        <div className="k-form-buttons">
                                            {buttonGroup(formRenderProps)}
                                        </div>
                                    </div>

                                </div>
                            </Card>
                        </FormElement>
                    </div>

                )
                }

            />
            {
                props.loading
                    ? <Spinner />
                    : null
            }
        </>
    )
}
const mapStateToProps = function (state: RootState) {
    return {
        user: state.user,
        loading: state.loading
    }
}
export default connect(mapStateToProps)(UserFormView);