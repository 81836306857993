import { Button } from "@progress/kendo-react-buttons";
import { Card } from "@progress/kendo-react-layout";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
    Grid,
    GridCellProps,
    GridColumn as Column, GridDataStateChangeEvent,
    GridFilterChangeEvent,
    GridSortChangeEvent
} from "@progress/kendo-react-grid";

import { ReviewRequestService } from "services/ReviewRequestService";
import DateCell from "components/CustomCells/DateCell";
import { EquipmentCell } from "components/CustomCells/ResourceCell";
import { DeleteButton, EditButton } from "components/CustomCells/ActionCell";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { FloatingLabel } from "@progress/kendo-react-labels";
import { ModalityService } from "services/ModalityService";
import { ModalityDTO } from "model/ModalityDTO";
import { RootState, useAppDispatch } from "store";
import { connect } from "react-redux";
import { CompositeFilterDescriptor, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { Spinner } from "components/Spinner/Spinner";
import { setLoading } from "store/slices/globalSlice";
import moment from "moment";
import { Status } from "model/StatusEnum";
import { ColumnMenu } from "components/CustomColumnMenus/ColumnMenu";
import { useNotification } from "components/Notifications/NotificationProvider";

function getStringFilters(filter: any) {
    let result: string = ""

    function getFilter(filters: any): any {
        let result: any[] = []
        for (let filter of filters) {
            if (filter && filter.field) {
                result.push(filter)
            } else {
                const res = getFilter(filter.filters)
                for (let re of res) {
                    result.push(re)
                }

            }
        }
        return result
    }
    console.log(filter);

    if (filter && filter.filters && filter.filters.length) {

        const filters = getFilter(filter.filters)
        result = filters.map((item: any) => {
            if (item.value instanceof Date) {
                return item.field + "|" + moment(item.value).format("DDMMYYYY")
            } else if (item.field === "statut") {
                return item.field + "|" + Status.find((status: any) => item.value === status.name)?.id
            } else {
                return item.field + "|" + item.value
            }

        }).join(",")
        return result
    }
}

const initialRequestsState = {
    content: [],
    pageNumber: 1,
    pageSize: 10,
    totalElements: 0,
    totalPages: 1
};
const ReviewRequestGridView = (props: any) => {

    let type = "maintenance";
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const notify = useNotification();
    const initialSort: SortDescriptor[] = [
        {
            field: "dateDebut",
            dir: "desc",
        }
    ];
    const initialPageState = {
        skip: 0,
        take: 10,
        buttonCount: 10,
        sort: initialSort,
        info: true,
        pageSizes: true,
        previousNext: true,
        responsive: true,
    };


    const ActionsCell = (props: GridCellProps) => (
        <td>
            <EditButton {...props} action={handleEdit} />
            <DeleteButton {...props} action={handleDelete} message={`Êtes-vous sûr de vouloir supprimer la demande n° ${props.dataItem.numero} ?`} />
        </td>
    );
    const initialFilter: CompositeFilterDescriptor = {
        logic: "and",
        filters: [],
    };


    const [visible, setVisible] = useState(false);
    const [modality, setModality] = useState(null);
    const [modalities, setModalities] = useState<ModalityDTO[]>([]);
    const [pageState, setPageState] = useState(initialPageState);
    const [title, setTitle] = useState("");
    const [requests, setRequests] = useState(initialRequestsState)
    const [filter, setFilter] = useState(initialFilter)

    let { skip, take, sort } = pageState;

    const getRequests = useCallback((pageSize: number | null = null, itemOffset: number | null = null, sortDesciptor: SortDescriptor[] | null = null) => {
        const sortedBy = sortDesciptor?.map((item: SortDescriptor) => item.field + "-" + item.dir).join(",")
        const requestService = new ReviewRequestService();
        const filteredBy = getStringFilters(filter)

        requestService.getAll(pageSize, itemOffset, sortedBy, filteredBy).then(result => {
            setRequests(result);
            dispatch(setLoading(false))
        })
            .catch((error) => notify({ message: error, type: "error" }))


    }, [setRequests, dispatch, filter])

    useEffect(() => {
        const { skip, take, sort } = pageState;
        getRequests(take, skip, sort)

    }, [pageState, filter, getRequests])

    const generalUrl = "/"

    useEffect(() => {
        switch (props.user.role) {
            case "CS":
            case "GL":
            case "BLM":
                break;
            default:
                navigate(generalUrl)
                break;
        }
    }, [props.user, navigate])
    useEffect(() => {
        setRequests(initialRequestsState)
        let modsByType: any[] = [];
        setTitle("Demandes de Maintenance")

        async function setUserModalities() {
            let userModalities = props.user.modalites
            if (!userModalities.length) {
                const modalityService = new ModalityService()
                await modalityService.getAll().then((result: any) => userModalities = result)
                    .catch((error) => notify({ message: error, type: "error" }))
            }
            setModalities(userModalities.filter((value: ModalityDTO) => { return modsByType.find((item: any) => item.nom.toUpperCase() === value.nom.toUpperCase()) }))
        }
        setUserModalities()
    }, [props.user.modalites])

    async function handleEdit(data: any) {
        navigate(`/demandes/${type}/edit/${data.id}`);
    }

    async function handleDelete(data: any) {
        const requestService = new ReviewRequestService();
        await requestService.delete(data.id)
            .then(() => {
                notify({ message: "Suppression terminée", type: "success" })
                getRequests(take, skip, sort)
            })
            .catch((error) => {
                notify({ message: error, type: "error" })
            });
    }


    const handlePageChange = (event: GridDataStateChangeEvent) => {
        const { skip, take } = event.dataState;
        setPageState({ ...pageState, skip: skip!, take: take! });

    };

    function handleSortChange(event: GridSortChangeEvent) {
        const sort = event.sort

        setPageState({ ...pageState, sort: sort });
    }



    const toggleDialog = () => {
        setVisible(!visible);
    };

    function addRequest() {

        navigate(`/demandes/${type}/create/${modality}`)
    }

    function handleChange(event: any) {
        if (event.value) {
            setModality(event.value.id);
        } else {
            setModality(null);
        }

    }

    function handleAdd() {
        if (modalities.length > 1) {
            toggleDialog()
        } else {
            navigate(`/demandes/${type}/create`)
        }
    }

    function handleFilterChange(e: GridFilterChangeEvent) {
        const result: any = e.filter
        setFilter(result)
    }


    return (

        <div className='wrapper'>
            {visible && modalities.length > 1 && (
                <Dialog title={"Nouvelle demande de maintenance"} onClose={toggleDialog} width={500}>
                    <div className="col">
                        <span>Veuillez choisir une modalité</span>
                        <FloatingLabel
                            label="Modalité"
                            editorId="modality"
                            editorValue={modality}
                            className="popup-label"
                        >
                            <ComboBox
                                id="modality"

                                data={modalities}
                                dataItemKey="id"
                                textField="nom"
                                onChange={handleChange}
                            />
                        </FloatingLabel>
                    </div>
                    <DialogActionsBar layout="end">
                        <Button
                            fillMode="outline"
                            themeColor="base"
                            onClick={toggleDialog}
                        >
                            Retour
                        </Button>
                        <Button
                            fillMode="solid"
                            themeColor="primary"
                            onClick={addRequest}
                            disabled={modality === null}
                        >
                            Créer
                        </Button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <div className="row" style={{ justifyContent: "space-between", alignItems: "center" }}>
                <span className="section-title">{title}</span>
                {props.user && props.user.role !== "BLM" &&
                    <Button
                        themeColor={"primary"}
                        onClick={handleAdd}
                    >
                        Ajouter une demande
                    </Button>
                }
            </div>
            <Card>
                {requests
                    ?
                    <Grid
                        data={orderBy(requests.content, sort)}

                        sortable={true}
                        sort={sort}
                        onSortChange={handleSortChange}
                        skip={skip}
                        take={take}
                        onDataStateChange={handlePageChange}
                        pageable={true}
                        total={requests.totalElements}
                        pageSize={10}
                        filter={filter}
                        onFilterChange={handleFilterChange}
                    >
                        <Column key="numm" title="Numéro de demande" columnMenu={ColumnMenu} field="numero" />,
                        <Column key="dtem" title="Date de création" columnMenu={ColumnMenu} field="dateCreation" width={150} cell={(props: GridCellProps) => {
                            return DateCell(props.dataItem.dateCreation, "DD/MM/YYYY")
                        }} />,
                        <Column key="equm" title="Equipements" columnMenu={ColumnMenu} field="equipementsNom" cell={EquipmentCell} />,
                        <Column key="strm" title="Date de début" columnMenu={ColumnMenu} field="dateDebut" width={150} cell={(props: GridCellProps) => {
                            return DateCell(props.dataItem.dateDebut, "DD/MM/YYYY")
                        }} />,
                        <Column key="endm" title="Date de fin" columnMenu={ColumnMenu} field="dateFin" width={150} cell={(props: GridCellProps) => {
                            return DateCell(props.dataItem.dateFin, "DD/MM/YYYY")
                        }} />,
                        <Column key="actm" title="Actions" width={110} cell={ActionsCell} sortable={false} filterable={false} />
                    </Grid>

                    :
                    <div>Aucunes demandes trouvées</div>
                }
            </Card>
            {props.loading
                ? <Spinner />
                : null
            }
        </div>
    )
}
const mapStateToProps = function (state: RootState) {
    return {
        user: state.user,
        loading: state.loading
    }
}
export default connect(mapStateToProps)(ReviewRequestGridView);