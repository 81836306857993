import { EquipmentType } from "./EquipmentType";
import { Modality } from "./ModalityDTO";
import { PostResourceDTO, ResourceDTO } from "./ResourceDTO";

export interface EquipmentDTO extends ResourceDTO {
  numeroDeSerie: string,
  numeroDeCommande: string,
  dateDeSortieUsine: string | null,
  actif: boolean,
}

export interface PostEquipmentDTO extends PostResourceDTO {
  numeroDeSerie: string,
  numeroDeCommande: string,
  dateDeSortieUsine: string | null,
  actif: boolean,
}

export function GetToPostEquipment(data: EquipmentDTO): PostEquipmentDTO {
  const format: PostEquipmentDTO = {
    numeroDeSerie: data.numeroDeSerie,
    numeroDeCommande: data.numeroDeCommande,
    dateDeSortieUsine: data.dateDeSortieUsine === "" ? null : data.dateDeSortieUsine,
    type: data.type,
    actif: data.actif,
    nom: data.nom,
    modaliteId: data.modalite.id
  }
  return format
}

export interface IEquipment extends EquipmentDTO {
}

export class Equipment implements IEquipment {
  numeroDeSerie: string = "";
  numeroDeCommande = "";
  dateDeSortieUsine = "";
  type = EquipmentType.ECHO;
  actif: boolean = true;
  nom: string = "";
  modalite = new Modality();
  id: number = 0;

  constructor(
    nom: string,
    type: EquipmentType,
    modalite: Modality= new Modality(),
    numeroDeSerie: string = "",
    numeroDeCommande: string = "",
    dateDeSortieUsine: string = "",
    actif: boolean,
    id: number = 0,
  ) {    
    this.actif = actif;
    this.numeroDeSerie = numeroDeSerie;
    this.numeroDeCommande = numeroDeCommande;
    this.dateDeSortieUsine = dateDeSortieUsine;
    this.type = type;
    this.nom = nom;
    this.modalite = modalite;
    this.id = id;
  };
}

export class EquipmentFieldFormat {
  srNum: string = "";
  cmdNum: string = "";
  factoryDate: string | null = null;
  active: boolean = true;
  name: string = "";
  modality: Modality | null = null;
}