import { PostUserDTO, UserDTO } from "../model/UserDTO";
import axios from "utils/axios";
import { setAntiForgeryToken } from "store/slices/globalSlice";
import { ParamDTO } from "model/ParamDTO";

const API_URL = "/api/v1/parametrage";

function getAllParams() {
  return axios
    .get(`${API_URL}/`)
    .then((response) => {
      return response.data[0];
    })
    .catch((error) => {
      return error;
    });
}
function getParam(id: number) {
  return axios
    .get(`${API_URL}/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
function putParam(id: number, data: ParamDTO) {
  return axios.put(`${API_URL}/${id}`, data);
}

const ParamService = {
  getAllParams,
  getParam,
  putParam,
};

export default ParamService;
