export interface IADTO {
    nomPrenom:string,
    email: string
    id: number,
    actif:boolean
}

export interface IIA extends IADTO {
}

export class IA implements IIA {
    nomPrenom: string = "";
    email: string = "";
    id: number = 0;
    actif:boolean = true;
}