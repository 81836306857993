import axios from 'utils/axios';
import { IADTO } from 'model/IADTO';

const API_PATH = "/api/v1/ingenieurApplication";

export class IAService {
    getAll(pageSize: any, itemOffset: any, sortedBy: any) {
        if (!pageSize) {
            pageSize = 10
        }
        if (!itemOffset) {
            itemOffset = 0
        }
        if (!sortedBy) {
            sortedBy = "nomPrenom-asc"
        }
        return axios.get(`${API_PATH}?PageOffset=` + itemOffset + `&PageSize=` + pageSize + `&SortedBy=` + sortedBy)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error)

            });
    }
    getAllIA() {
        return axios.get(`${API_PATH}/Active`)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error)

            });
    }
    post(data: IADTO) {
        return axios.post(API_PATH, data)
            .catch(error => {
                console.log(error)

            });
    }
    get(id: number) {
        return axios.get(`${API_PATH}/${id}`)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error)

            });
    }
    patch(id: number, data: IADTO) {
        return axios.patch(`${API_PATH}/${id}`, data)
            .catch((error: any) => {
                console.log(error)

            });
    }
    put(id: number, data: IADTO) {
        return axios.put(`${API_PATH}/${id}`, data)
            .catch((error: any) => {
                console.log(error)
                throw error
            });
    }
    delete(id: number) {
        return axios.delete(`${API_PATH}/${id}`)
            .catch((error: any) => {
                console.log(error)
                throw error
            });
    }
} 