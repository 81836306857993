export enum Qualification {
  Positionnement,
  Vente,
  CongresAtelier,
  Autres,
}
export const QualificationMap = [
  { id: Qualification.Positionnement, nom: "Positionnement" },
  { id: Qualification.Vente, nom: "Vente" },
  { id: Qualification.CongresAtelier, nom: "Congrès/Atelier" },
  { id: Qualification.Autres, nom: "Autres" },
];
