import { EquipmentService } from 'services/EquipmentService';
import ResourceSchedulePanel from './ResourceSchedulePanel';


const ScheduleView = (props: any) => {
 
    return (
        <>
            <ResourceSchedulePanel resourceService={new EquipmentService()}/>
        </>

    );
}

export default ScheduleView;



