import { EquipmentDTO } from "./EquipmentDTO";
import { ModalityDTO } from "./ModalityDTO";
import { Request, RequestDTO } from "./RequestDTO";

export interface GetReviewRequestDTO extends RequestDTO {
  equipements: EquipmentDTO[],
  dateDebut: string,
  dateFin: string,
  modalite: ModalityDTO,
}

export interface PostReviewRequestDTO {
  commentaire: string,
  equipementIds: number[],
  dateDebut: string,
  dateFin: string,
  modaliteId: number,
}

export function GetToPostReview(data: GetReviewRequestDTO): PostReviewRequestDTO {
  const format: PostReviewRequestDTO = {
    commentaire: data.commentaire,
    dateDebut: data.dateDebut,
    dateFin: data.dateFin,
    equipementIds: data.equipements?.map((item: any) => item.id),
    modaliteId: data.modalite.id
  }
  return format;
}

export default class ReviewRequest extends Request {
  dateDebut = new Date().toDateString()
  dateFin = new Date().toDateString()
  equipements = []
  commentaire = ""
  finalite = null
  demandeId = 0
  modalite = {
    nom: "US",
    imputations:[],
    id: 1,
  }

  constructor() {
    super();
    this.type = 1
  }
}