import { DropDownList } from "@progress/kendo-react-dropdowns";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { useCallback } from "react";

const FieldComboBox = (fieldRenderProps: FieldRenderProps) => {

    const {
        // The meta props of the Field.
        validationMessage,
        touched,
        valid,
        defaultItem,
        // The input props of the Field.
        value,
        id,
        textField,
        dataItemKey,
        data,
        label,
        onChange,
        onFocus,
        onBlur,
        ...others

    } = fieldRenderProps;



    const showValidationMessage: string | false | null =
        touched && validationMessage;
    const errorId: string = showValidationMessage ? `${id}_error` : "";

    const callback = useCallback((event: any) => {
        onChange({ value: event.value });
    }, [onChange]);


    return (
        <FieldWrapper>
            <div onFocus={onFocus} onBlur={onBlur} className={others.required ? "required" : ""}>
                <DropDownList
                    defaultItem={defaultItem}
                    label={label}
                    data={data}
                    textField={textField}
                    dataItemKey={dataItemKey}
                    value={value}
                    valid={valid}
                    onChange={callback}
                />
                {showValidationMessage && (
                    <Error id={errorId}>{validationMessage}</Error>
                )}
            </div>

        </FieldWrapper>
    );

}

export default FieldComboBox