import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Form, Field, FormElement, FormRenderProps } from "@progress/kendo-react-form";

import { Button } from '@progress/kendo-react-buttons';
import { Card, StackLayout } from "@progress/kendo-react-layout";
import { Spinner } from "components/Spinner/Spinner";
import { Modality } from "model/ModalityDTO";
import FieldInput from "components/CustomFormFields/FieldInput";
import FieldComboBox from "components/CustomFormFields/FieldComboBox";
import { Equipment, EquipmentDTO, EquipmentFieldFormat, GetToPostEquipment } from "model/EquipmentDTO";
import { EquipmentService } from "services/EquipmentService";
import FieldDatePicker from "components/CustomFormFields/FieldDatePicker";
import FieldCheckBox from "components/CustomFormFields/FieldCheckBox";
import { commandNumberValidator, requiredValidator, serialNumberValidator } from "utils/FormValidators";
import { adjustForTimezone } from "utils/DateUtils";
import { useNotification } from "components/Notifications/NotificationProvider";
import { EquipmentTypeMap } from "model/EquipmentType";
import ResourceErrorPopup from "components/CustomPopups/ResourceErrorPopup";
import RequestErrorPopup from "components/CustomPopups/RequestErrorPopup";
import EquipmentErrorPopup from "components/CustomPopups/EquipmentErrorPopup";

const NAVIGATE_PATH = "/admin/equipements"

const modality: Modality = {
    nom: "US",
    imputations: [],
    id: 1
}

const EquipmentFormView = (props: any) => {

    const equipmentFieldFormat = new EquipmentFieldFormat();


    const navigate = useNavigate();
    const notify = useNotification();
    let { id } = useParams();
    const [equipment, setEquipment] = useState<EquipmentDTO | null>(null);
    const [init, setInit] = useState(equipmentFieldFormat);
    const [loading, setLoading] = useState(false);

    const [visible, setVisible] = useState(false)
    const [bookedError, setBookedError] = useState<any[]>([])

    useEffect(() => {
        function handleInit(): any {
            if (props.layout === "edit" && id) {
                const equipmentService = new EquipmentService();
                equipmentService.get(+id).then((data: EquipmentDTO) => {
                    if (data) {
                        console.log(data);

                        setEquipment(data);
                        const res = {
                            type: EquipmentTypeMap.find(i => i.id === data.type),
                            srNum: data.numeroDeSerie,
                            cmdNum: data.numeroDeCommande,
                            factoryDate: data.dateDeSortieUsine ? data.dateDeSortieUsine : "",
                            active: data.actif,
                            name: data.nom,
                            modality: data.modalite,
                        }
                        console.log(res);

                        setInit(res)
                    }
                });
            }

        }
        handleInit();
    }, [id, props.layout])

    const handleSubmit = (dataItem: { [name: string]: any; }) => {

        let result = new Equipment(
            dataItem.name,
            dataItem.type.id,
            modality,
            dataItem.srNum,
            dataItem.cmdNum?dataItem.cmdNum:null,
            dataItem.factoryDate ? adjustForTimezone(new Date(dataItem.factoryDate)).toISOString() : undefined,
            dataItem.active,
        );

        console.log(result);

        const equipmentService = new EquipmentService();
        if (props.layout === "edit" && id) {
            toggleLoading()
            equipmentService.put(+id, GetToPostEquipment(result)).then(() =>
                navigate(NAVIGATE_PATH)
            ).catch((error) => {
                if (error.request && error.request.status === 409) {
                    let result: any[] = []
                    if (error.request && error.request.response) {
                        try {
                            const resultJSON = JSON.parse(error.request.response)
                            console.log(resultJSON.errors[0]);
                            
                            for (const error of resultJSON.errors) {
                                result.push(error)
                            }
                            setBookedError(result)

                        } catch (error) {
                            notify({ message: error, type: "error" })
                        }
                    }
                    togglePopupError()
                    setLoading(false)
                } else
                    notify({ message: error, type: "error" })
            });
        } else {
            toggleLoading()
            equipmentService.post(GetToPostEquipment(result))
                .then(() => {
                    navigate(NAVIGATE_PATH)
                }).catch((error) => {
                    notify({ message: error, type: "error" })
                    setLoading(false)
                });
        }
    };

    function toggleLoading() {
        setLoading(!loading);
    }
    const buttonGroup = (formRenderProps: any) => {
        return (
            <>
                <Button
                    name="cancel"
                    type="button"
                    fillMode="outline"
                    onClick={() => { navigate(NAVIGATE_PATH) }}
                >
                    Retour
                </Button>
                <Button
                    name="submit"
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                    themeColor={"primary"}
                >
                    Enregistrer
                </Button>
            </>

        )
    }

    function togglePopupError() {
        setVisible(!visible)
    }

    const anchor = useRef<HTMLButtonElement | null>(null);

    return (
        <>
            <EquipmentErrorPopup anchor={anchor.current} togglePopupError={togglePopupError} type={"modifier"} bookedError={bookedError} visible={visible} />
            <Form
                initialValues={init}
                key={JSON.stringify(init)}
                onSubmit={handleSubmit}
                render={(formRenderProps: FormRenderProps) => (
                    <div className='wrapper'>
                        <FormElement>

                            <div className="row" style={{ justifyContent: "space-between", alignItems: "center" }}>
                                <span className="section-title">{props.layout === "edit" ? "Equipement " + equipment?.nom + " SN : " + equipment?.numeroDeSerie : "Ajouter un équipement"}</span>
                                <div className="k-form-buttons"
                                    style={{ marginTop: "0" }}
                                >
                                    {buttonGroup(formRenderProps)}
                                </div>
                            </div>
                            <Card>
                                <div>

                                    <div className='form-section'>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}
                                        >
                                            <Field
                                                name="active"
                                                label={"Equipement actif"}
                                                component={FieldCheckBox}
                                            />
                                        </StackLayout>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}
                                        >
                                            <Field
                                                name={"name"}
                                                label={"Libellé"}
                                                required={true}
                                                component={FieldInput}
                                                validator={requiredValidator}
                                            />
                                            <Field
                                                name={"srNum"}
                                                label={"N° de série"}
                                                required={true}
                                                component={FieldInput}
                                                validator={serialNumberValidator}
                                            />
                                        </StackLayout>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}
                                        >
                                            <Field
                                                name={"cmdNum"}
                                                label={"N° de commande"}
                                                component={FieldInput}
                                                validator={commandNumberValidator}
                                            />
                                            <Field
                                                name={"factoryDate"}
                                                label={"Date de sortie d'usine"}
                                                component={FieldDatePicker}
                                            />
                                        </StackLayout>
                                        <StackLayout
                                            orientation="horizontal"
                                            gap={20}
                                        >
                                            <Field
                                                name={"type"}
                                                label={"Type d'équipement"}
                                                required={true}
                                                id={"type"}
                                                data={EquipmentTypeMap}
                                                textField="nom"
                                                dataItemKey="id"
                                                component={FieldComboBox}
                                                validator={requiredValidator}
                                            />
                                        </StackLayout>
                                    </div>

                                </div>
                            </Card>
                        </FormElement>
                    </div>

                )
                }

            />
            {
                loading
                    ? <Spinner />
                    : null
            }
        </>
    )
}

export default EquipmentFormView;