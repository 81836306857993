import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { FloatingLabel } from "@progress/kendo-react-labels";
import { IADTO } from "model/IADTO";
import { useState } from "react";

interface IAFormProps {
    onCancel: () => void;
    onSave: (item: IADTO) => void;
    item: IADTO;
}

const IAFormView = (props: IAFormProps) => {
    const [data, setData] = useState(props.item);

    function handleChangeName(event: any) {
        setData({ ...data, nomPrenom: event.value })
    }
    function handleChangeActif(event: any) {
        setData({ ...data, actif: event.value })
    }

    function handleChangeMail(event: any) {
        setData({ ...data, email: event.value });
    }

    return (<Dialog
        title={props.item.id !== 0 ? `Modifier l'IA ${props.item.nomPrenom}` : 'Nouvel IA'}
        onClose={props.onCancel}
        width={500}>
        <div className="col edit-object">
            <Checkbox
                label="Actif"
                id="active"
                value={data.actif}
                onChange={handleChangeActif}
            />
            <FloatingLabel
                label="Nom"
                editorId="name"
                editorValue={data.nomPrenom}
                className="popup-label required"
            >
                <Input
                    id="name"
                    value={data.nomPrenom}
                    onChange={handleChangeName}
                />
            </FloatingLabel>
            <FloatingLabel
                label="Email"
                editorId="email"
                editorValue={data.email}
                className="popup-label required"
            >
                <Input
                    id="email"
                    value={data.email}
                    onChange={handleChangeMail}
                />
            </FloatingLabel>

        </div>
        <DialogActionsBar layout="end">
            <Button fillMode="outline"
                themeColor="base"
                onClick={() => props.onCancel()}>
                Annuler
            </Button>
            <Button
                fillMode="solid"
                themeColor="primary"
                onClick={() => props.onSave(data)}
                disabled={data.nomPrenom === "" || data.email === ""}>
                {data.id === 0 ? 'Créer' : 'Enregistrer'}
            </Button>
        </DialogActionsBar>
    </Dialog>)
}

export default IAFormView;