import { GridCellProps } from "@progress/kendo-react-grid";

export const EquipmentCell = (props: GridCellProps) => {
    const value = props.dataItem.equipements;
    let equipments: any[] = [];
    const key = props.dataItem.id + "_equipment"
    if (value) {
        for (let equipment of value) {
            equipments.push(equipment.nom)
        }
    }
    return (
        <td>
            {!value ? "" : equipments.length > 1 ? <div className="resource-cell"><span key={key} className="resource-main">{equipments[0]}</span><span key={key + "-spare"} className="resource-spare">{equipments.slice(1).map((item: any) => <span key={key + "-spare-item"} className="resource-spare-item">{item}</span>)}</span></div> : <span key={key} className="resource-main">{equipments[0]}</span>}
        </td>
    );
};

export const EquAssignationCell = (props: GridCellProps) => {
    const value = props.dataItem.finalite;
    let result = "";
    if (value !== null || value !== undefined) {
        if (value === 0) {
            result = "Démonstration";
        } else if (value === 1) {
            result = "Prêt";
        }
    }

    return (
        <td>
            <span className="status-cell">{result}</span>
        </td>
    );
};

export const EquTypeCell = (props: GridCellProps) => {
    const value = props.dataItem.type;
    let result = "";
    if (value !== null || value !== undefined) {
        if (value === 0) {
            result = "Echographe";
        } else if (value === 1) {
            result = "Accessoire";
        }
    }

    return (
        <td>
            <span className="status-cell">{result}</span>
        </td>
    );
};