import { RootState } from "store";
import { connect } from "react-redux";
import { Popup } from "@progress/kendo-react-popup";


const EquipmentErrorPopup = (props: any) => {

    return (
        <>
            <Popup anchor={props.anchor} className="popup-real" show={props.visible} popupClass={"popup-error"}>
                <span className="popup-error-title row">
                    <i className="icon-warning" />
                    Impossible de {props.type} l'équipement
                    <span className="spacer" />
                    <i className="icon-close" onClick={props.togglePopupError} />
                </span>
                <span className="popup-error-subtitle" >Cet équipement est déjà réservé dans la/les demande(s) suivante(s) : </span>
                {props.bookedError.map((request: any) => {
                    
                    return (<div key={request.Id}>
                        <span>{"- "}<b>{request.Numero}</b>{`, du ${new Date(request.DateDebut).toLocaleDateString("fr-FR", { day: 'numeric', month: 'long', year: 'numeric' })} au ${new Date(request.DateFin).toLocaleDateString("fr-FR", { day: 'numeric', month: 'long', year: 'numeric' })}`}</span>

                    </div>)
                })}

            </Popup>
        </>
    )
}
const mapStateToProps = function (state: RootState) {
    return {
        user: state.user,
        loading: state.loading
    }
}
export default connect(mapStateToProps)(EquipmentErrorPopup);