import axios from 'utils/axios';
import { RoleDTO } from '../model/RoleDTO';

export class RoleService {
    getAll() {
        return axios.get(`/api/v1/roles`)
            .then(response => {
                const roles: RoleDTO[] = response.data;
                return roles;
            })
            .catch(error => {
                console.log(error)

            });
    }
    get(id: number) {
        return axios.get(`/api/v1/roles/` + id)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error)

            });
    }
} 