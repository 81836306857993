import { RootState } from "store";
import { connect } from "react-redux";
import { Popup } from "@progress/kendo-react-popup";


const ResourceErrorPopup = (props: any) => {



    return (
        <>
            <Popup anchor={props.anchor} className="popup-real" show={props.visible} popupClass={"popup-error"}>
                <span className="popup-error-title row">
                    <i className="icon-warning" />
                    Impossible {props.type} la demande
                    <span className="spacer" />
                    <i className="icon-close" onClick={props.togglePopupError} />
                </span>
                <span className="popup-error-subtitle" >les ressources suivantes ne sont pas disponibles : </span>
                {props.bookedError.map((resource: any) => {
                    return (<div key={resource.Id}>
                        <span>{`- ${resource.NumeroDeSerie ? "Equipement" : "Intervenant"} :`}<b>{resource.Nom}</b></span>

                    </div>)
                })}

            </Popup>
        </>
    )
}
const mapStateToProps = function (state: RootState) {
    return {
        user: state.user,
        loading: state.loading
    }
}
export default connect(mapStateToProps)(ResourceErrorPopup);