import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { SchedulerItem, SchedulerItemProps } from "@progress/kendo-react-scheduler";
import { UserDTO } from "model/UserDTO";
import moment from "moment";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "store";

const mapStateToProps = function (state: RootState) {
    return {
        user: state.user,
        loading: state.loading
    }
}

interface ResourceSchedulerItemProps extends SchedulerItemProps {
    user: UserDTO | null
    loading: boolean
}

const CustomResourceItem = (props: ResourceSchedulerItemProps) => {
    const occupation = props.dataItem.occupation

    const user = props.user

    const [visible, setVisible] = useState(false)

    function togglePopup() {
        setVisible(!visible)

    }

    function canModify() {
        if (user) {

            if (user.role === "IC" && occupation.demande.userId === user.id) {
                return true
            } else if (user.role === "GL") {
                return true
            } else if (user.role === "BLM" && occupation.demande.type === 1) {
                return true
            } else if (user.role === "CS" && occupation.demande.type === 2) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    function type() {
        let title: string = ""
        switch (occupation.demande.type) {
            case 0:
                title = "Demande de démonstration"
                break;
            case 1:
                title = "Demande de prêt"
                break;
            case 2:
                title = "Demande de maintenance"
        }
        return title
    }
    function typeR() {
        let title: string = ""
        switch (occupation.demande.type) {
            case 0:
                title = "demo"
                break;
            case 1:
                title = "pret"
                break;
            case 2:
                title = "maintenance"
                break;
        }
        return title
    }

    const dates = {
        start: moment(occupation.dateDeDebut).format("DD/MM/YYYY"),
        end: moment(occupation.dateDeFin).format("DD/MM/YYYY")
    }

    const navigate = useNavigate()

    return (
        <>
            {visible && (<Dialog
                title={type()}
                onClose={togglePopup}
                width={500}>
                <div className="col object-summary">
                    <div className='summary-line row'>
                        <span>Nom du demandeur : </span>
                        <span>{`${occupation.demande.userFirstName} ${occupation.demande.userLastName}`}</span>
                    </div>
                    {occupation.demande.client ?
                        <div className='summary-line row'>
                            <span>Raison sociale du client : </span>
                            <span>{occupation.demande.client}</span>
                        </div>
                        : null
                    }
                    {occupation.demande.ville ?
                        <div className='summary-line row'>
                            <span>Ville : </span>
                            <span>{occupation.demande.ville}</span>
                        </div>
                        : null
                    }
                    <div className='summary-line row'>
                        <span>Dates : </span>
                        <span>{`du ${dates.start} au ${dates.end}`}</span>
                    </div>
                </div>
                {canModify() ?
                    <DialogActionsBar layout="end">
                        <Button
                            fillMode="solid"
                            themeColor="primary"
                            onClick={() => { navigate(`demandes/${typeR()}/edit/${occupation.demande.id}`) }}
                        >
                            Modifier
                        </Button>
                    </DialogActionsBar>
                    : null}
            </Dialog>)}
            <SchedulerItem
                {...props}
                style={{
                    ...props.style,
                    background: `${props.dataItem.color}`,
                }}
                onClick={togglePopup}
            />
        </>
    )
};
export default connect(mapStateToProps)(CustomResourceItem)