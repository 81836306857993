import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux"
import store, { persistor } from 'store';
import { PersistGate } from 'redux-persist/integration/react';

import {setUpInterceptor} from './utils/axios/interceptors';
import NotificationProvider from 'components/Notifications/NotificationProvider';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
setUpInterceptor(store)

root.render(
  <React.StrictMode>
      <Router>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <NotificationProvider>
              <App />
            </NotificationProvider>
          </PersistGate>
        </Provider>
      </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
