import { DemandeTypes } from 'model/DemandeTypesEnum';
import axios from 'utils/axios';
import { GetDemoRequestDTO, PostDemoRequestDTO } from '../model/DemoRequestDTO';

export class DemonstrationRequestService {
    get(id: number) {
        return axios.get(`/api/v1/demandes/demonstration/` + id)
            .then(response => {
                if (response.data && response.data.type === DemandeTypes.Demonstration.id) {

                    const result: GetDemoRequestDTO = response.data
                    return result
                } else {
                    // Cela ne devrait jamais arriver
                    throw new Error("Mauvais type de demande récupérée")
                }                
            })
            .catch(error => {
                console.log(error)
                throw error;
            });
    }
    delete(id: number) {
        return axios.delete(`/api/v1/demandes/demonstration` + id)
            .catch((error: any) => {
                console.log(error)
                throw error;
            });
    }
    getAll(pageSize: any, itemOffset: any, sortedBy: any, filteredBy: any) {
        if (!pageSize) {
            pageSize = 10
        }
        if (!itemOffset) {
            itemOffset = 0
        }
        if (!sortedBy) {
            sortedBy = "statut-asc,dateCreation-asc"
        }
        let filtered="&FilteredBy="
        if (filteredBy) {
            filtered += filteredBy 
        }else{
            filtered = ""
        }
        return axios.get(`/api/v1/demandes/demonstration?PageOffset=` + itemOffset + `&PageSize=` + pageSize + `&SortedBy=` + sortedBy+filtered)
            .then((response: any) => {
                return response.data;
            })
            .catch((error: any) => {
                console.log(error)
                throw error;
            });

    }
    create(data: PostDemoRequestDTO) {
        return axios.post(`/api/v1/demandes/demonstration`, data)
            .then((response: any) => {
                return response.data
            })
            .catch((error: any) => {
                console.log(error)
                throw error;
            });
    }

    update(id: number, data: PostDemoRequestDTO) {
        return axios.put(`/api/v1/demandes/demonstration/` + id, data)
            .catch((error: any) => {
                console.log(error);
                throw error;
            });

    }
    publish(id: number, data: PostDemoRequestDTO) {
        return axios.put(`/api/v1/demandes/demonstration/` + id + `/publish`, data)
            .catch((error: any) => {
                console.log(error);
                throw error;
            });
    }
    accept(id: number, data: PostDemoRequestDTO) {
        return axios.put(`/api/v1/demandes/demonstration/` + id + `/accept`, data)
            .catch((error: any) => {
                console.log(error);
                throw error;
            });
    }
    deny(id: number, data: PostDemoRequestDTO) {
        return axios.put(`/api/v1/demandes/demonstration/` + id + `/deny`, data)
            .catch((error: any) => {
                console.log(error);
                throw error;
            });
    }
    cancel(id: number, data: PostDemoRequestDTO) {
        return axios.put(`/api/v1/demandes/demonstration/` + id + `/cancel`, data)
            .catch((error: any) => {
                console.log(error);
                throw error;
          });
    }
} 