import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Label, Error } from "@progress/kendo-react-labels";
import { ListBox, ListBoxItemClickEvent, ListBoxToolbar, ListBoxToolbarClickEvent, processListBoxData } from "@progress/kendo-react-listbox";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { EquipmentService } from "services/EquipmentService";
import { setLoading } from "store/slices/globalSlice";
import { useAppDispatch } from "store";
import { EquipmentType } from "model/EquipmentType";
import { Equipment } from "model/EquipmentDTO";

export const FieldEquipment = (fieldRenderProps: FieldRenderProps) => {

    const SELECTED_FIELD = "selected";
    const dispatch = useAppDispatch();

    const [available, setAvailable] = useState<{ id: number, name: string, mobility: number, selected: string }[]>([])
    const [requested, setRequested] = useState<{ id: number, name: string, mobility: number, selected: string }[]>([])

    const {
        // The meta props of the Field.
        validationMessage,
        touched,
        visited,
        modified,
        valid,
        // The input props of the Field.
        value,
        id,
        disabled,
        optional,
        label,
        onChange,
        onFocus,
        onBlur,
        // The custom props that you passed to the Field.
        ...others
    } = fieldRenderProps;


    useEffect(() => {

        const equipmentService = new EquipmentService();
        async function fetchEquipmentData() {
            console.log(others);

            if (others.request.dateDeLivraison && others.request.dateDeRetrait && others.request.modalite.id) {
                console.log(others.request)
                const startDate = moment(others.request.dateDeLivraison).format("YYYY-MM-D")
                let endDate = ""
                if (others.request.dateDeLivraison === others.request.dateDeRetrait) {
                    endDate = moment(others.request.dateDeRetrait).add(1, 'd').format("YYYY-MM-D")
                } else {
                    endDate = moment(others.request.dateDeRetrait).format("YYYY-MM-D")
                }
                const equipmentsResult: any[] = await equipmentService.getAvailables(startDate, endDate, EquipmentType.ECHO);

                if (equipmentsResult) {
                    return equipmentsResult
                }
            }
        }
        fetchEquipmentData().then((result: any) => { if (result) initialiseEdit(result); dispatch(setLoading(false)) });


    }, [others.request.dateDeLivraison, others.request.dateDeRetrait, others.request.modalite.id])

    function initialiseEdit(result: any) {
        let req: any[] = [];
        let ava: any[] = [];
        if (others.request.equipements) {
            req = others.request.equipements.filter((e: Equipment) => e.type === EquipmentType.ECHO).map((item: any) => ({ "id": item.id, "name": item.name || item.nom, "selected": false }))
        }
        ava = result.map((item: any) => ({ "id": item.id, "name": item.name || item.nom, "selected": false }))

        setAvailable([...ava]);
        setRequested(req);
    }

    const handleItemAvailableClick = (event: ListBoxItemClickEvent) => {
        const newAvailable = available.map((item: any) => {
            if (item.id === event.dataItem.id) {
                item[SELECTED_FIELD] = !item[SELECTED_FIELD];
            } else if (!event.nativeEvent.ctrlKey) {
                item[SELECTED_FIELD] = false;
            }
            return item;
        })
        const newRequested = requested.map((item: any) => {
            item[SELECTED_FIELD] = false;
            return item;
        })
        setAvailable(newAvailable);
        setRequested(newRequested);
    };

    const handleItemRequestedClick = (event: ListBoxItemClickEvent) => {

        const newRequested = requested.map((item: any) => {
            if (item.id === event.dataItem.id) {
                item[SELECTED_FIELD] = !item[SELECTED_FIELD];
            } else if (!event.nativeEvent.ctrlKey) {
                item[SELECTED_FIELD] = false;
            }
            return item;
        })
        const newAvailable = available.map((item: any) => {
            item[SELECTED_FIELD] = false;
            return item;
        })
        setAvailable(newAvailable);
        setRequested(newRequested);
    };

    const handleToolBarClick = async (e: ListBoxToolbarClickEvent) => {
        let toolName: string = e.toolName || "";
        let result: any = processListBoxData(
            available,
            requested,
            toolName,
            SELECTED_FIELD
        );
        const newAvailable = result.listBoxOneData.filter((element: any, index: number, self: any) => {
            return self.findIndex((t: any) => (
                t.id === element.id
            )) === index;
        });

        const newRequested = result.listBoxTwoData.filter((element: any, index: number, self: any) => {
            return self.findIndex((t: any) => (
                t.id === element.id)) === index;
        })
        setAvailable(newAvailable);
        setRequested(newRequested);
    };

    const showValidationMessage: string | false | null =
        visited && validationMessage;
    const errorId: string = showValidationMessage ? `${id}_error` : "";

    const callback = useCallback(() => {
        onChange({ value: requested });
    }, [onChange, requested]);

    useEffect(() => {
        callback();

    }, [callback, requested])


    return (
        <FieldWrapper className={"col" + (others.required ? " required" : "")} >
            <Label className="listbox-field-title"
                editorId={id}
                editorValid={valid}
                editorDisabled={disabled}
                optional={optional}>
                {label}
            </Label>
            <div className={"listbox-field-layout" + (showValidationMessage ? " listbox-error" : "")} onFocus={onFocus} onBlur={onBlur}>
                <div className="listbox-item listbox-one" onFocus={onFocus} onBlur={onBlur}>
                    <Label className="listbox-title">Disponibles</Label>
                    <ListBox
                        data={available}
                        textField="name"
                        selectedField={SELECTED_FIELD}
                        onItemClick={(e: ListBoxItemClickEvent) =>
                            handleItemAvailableClick(e)
                        }
                        toolbar={() => {
                            return (
                                <ListBoxToolbar
                                    tools={[
                                        "moveUp",
                                        "moveDown",
                                        "transferTo",
                                        "transferFrom",
                                        "transferAllTo",
                                        "transferAllFrom",
                                    ]}
                                    data={available}
                                    dataConnected={requested}
                                    onToolClick={handleToolBarClick}
                                />
                            );
                        }}
                    />
                </div>
                <div className="listbox-item k-pl-0">
                    <Label className="listbox-title">Sélectionnés</Label>
                    <ListBox
                        data={requested}
                        textField="name"
                        selectedField={SELECTED_FIELD}
                        onItemClick={(e: ListBoxItemClickEvent) =>
                            handleItemRequestedClick(e)
                        }
                    />
                    {showValidationMessage && (
                        <Error id={errorId}>{validationMessage}</Error>
                    )}
                </div>
            </div>
        </FieldWrapper>
    )

}
export const FieldEquipmentAccess = (fieldRenderProps: FieldRenderProps) => {

    const SELECTED_FIELD = "selected";
    const dispatch = useAppDispatch();

    const [available, setAvailable] = useState<{ id: number, name: string, mobility: number, selected: string }[]>([])
    const [requested, setRequested] = useState<{ id: number, name: string, mobility: number, selected: string }[]>([])

    const {
        // The meta props of the Field.
        validationMessage,
        touched,
        visited,
        modified,
        valid,
        // The input props of the Field.
        value,
        id,
        disabled,
        optional,
        label,
        onChange,
        onFocus,
        onBlur,
        // The custom props that you passed to the Field.
        ...others
    } = fieldRenderProps;


    useEffect(() => {

        const equipmentService = new EquipmentService();
        async function fetchEquipmentData() {
            console.log(others);

            if (others.request.dateDeLivraison && others.request.dateDeRetrait && others.request.modalite.id) {
                console.log(others.request)
                const startDate = moment(others.request.dateDeLivraison).format("YYYY-MM-D")
                let endDate = ""
                if (others.request.dateDeLivraison === others.request.dateDeRetrait) {
                    endDate = moment(others.request.dateDeRetrait).add(1, 'd').format("YYYY-MM-D")
                } else {
                    endDate = moment(others.request.dateDeRetrait).format("YYYY-MM-D")
                }
                const equipmentsResult: any[] = await equipmentService.getAvailables(startDate, endDate, EquipmentType.ACCESSORY);

                if (equipmentsResult) {
                    return equipmentsResult
                }
            }
        }
        fetchEquipmentData().then((result: any) => { if (result) initialiseEdit(result); dispatch(setLoading(false)) });


    }, [others.request.dateDeLivraison, others.request.dateDeRetrait, others.request.modalite.id])

    function initialiseEdit(result: any) {
        let req: any[] = [];
        let ava: any[] = [];
        if (others.request.equipements) {
            req = others.request.equipements.filter((e: Equipment) => e.type === EquipmentType.ACCESSORY).map((item: any) => ({ "id": item.id, "name": item.name || item.nom, "selected": false }))
        }
        ava = result.map((item: any) => ({ "id": item.id, "name": item.name || item.nom, "selected": false }))

        setAvailable([...ava]);
        setRequested(req);
    }

    const handleItemAvailableClick = (event: ListBoxItemClickEvent) => {
        const newAvailable = available.map((item: any) => {
            if (item.id === event.dataItem.id) {
                item[SELECTED_FIELD] = !item[SELECTED_FIELD];
            } else if (!event.nativeEvent.ctrlKey) {
                item[SELECTED_FIELD] = false;
            }
            return item;
        })
        const newRequested = requested.map((item: any) => {
            item[SELECTED_FIELD] = false;
            return item;
        })
        setAvailable(newAvailable);
        setRequested(newRequested);
    };

    const handleItemRequestedClick = (event: ListBoxItemClickEvent) => {

        const newRequested = requested.map((item: any) => {
            if (item.id === event.dataItem.id) {
                item[SELECTED_FIELD] = !item[SELECTED_FIELD];
            } else if (!event.nativeEvent.ctrlKey) {
                item[SELECTED_FIELD] = false;
            }
            return item;
        })
        const newAvailable = available.map((item: any) => {
            item[SELECTED_FIELD] = false;
            return item;
        })
        setAvailable(newAvailable);
        setRequested(newRequested);
    };

    const handleToolBarClick = async (e: ListBoxToolbarClickEvent) => {
        let toolName: string = e.toolName || "";
        let result: any = processListBoxData(
            available,
            requested,
            toolName,
            SELECTED_FIELD
        );
        const newAvailable = result.listBoxOneData.filter((element: any, index: number, self: any) => {
            return self.findIndex((t: any) => (
                t.id === element.id
            )) === index;
        });

        const newRequested = result.listBoxTwoData.filter((element: any, index: number, self: any) => {
            return self.findIndex((t: any) => (
                t.id === element.id)) === index;
        })
        setAvailable(newAvailable);
        setRequested(newRequested);
    };

    const showValidationMessage: string | false | null =
        visited && validationMessage;
    const errorId: string = showValidationMessage ? `${id}_error` : "";

    const callback = useCallback(() => {
        onChange({ value: requested });
    }, [onChange, requested]);

    useEffect(() => {
        callback();

    }, [callback, requested])


    return (
        <FieldWrapper className={"col" + (others.required ? " required" : "")} >
            <Label className="listbox-field-title"
                editorId={id}
                editorValid={valid}
                editorDisabled={disabled}
                optional={optional}>
                {label}
            </Label>
            <div className={"listbox-field-layout" + (showValidationMessage ? " listbox-error" : "")} onFocus={onFocus} onBlur={onBlur}>
                <div className="listbox-item listbox-one" onFocus={onFocus} onBlur={onBlur}>
                    <Label className="listbox-title">Disponibles</Label>
                    <ListBox
                        data={available}
                        textField="name"
                        selectedField={SELECTED_FIELD}
                        onItemClick={(e: ListBoxItemClickEvent) =>
                            handleItemAvailableClick(e)
                        }
                        toolbar={() => {
                            return (
                                <ListBoxToolbar
                                    tools={[
                                        "moveUp",
                                        "moveDown",
                                        "transferTo",
                                        "transferFrom",
                                        "transferAllTo",
                                        "transferAllFrom",
                                    ]}
                                    data={available}
                                    dataConnected={requested}
                                    onToolClick={handleToolBarClick}
                                />
                            );
                        }}
                    />
                </div>
                <div className="listbox-item k-pl-0">
                    <Label className="listbox-title">Sélectionnés</Label>
                    <ListBox
                        data={requested}
                        textField="name"
                        selectedField={SELECTED_FIELD}
                        onItemClick={(e: ListBoxItemClickEvent) =>
                            handleItemRequestedClick(e)
                        }
                    />
                    {showValidationMessage && (
                        <Error id={errorId}>{validationMessage}</Error>
                    )}
                </div>
            </div>
        </FieldWrapper>
    )

}
export const FieldEquipmentReview = (fieldRenderProps: FieldRenderProps) => {

    const SELECTED_FIELD = "selected";
    const dispatch = useAppDispatch();

    const [available, setAvailable] = useState<{ id: number, name: string, mobility: number, selected: string }[]>([])
    const [requested, setRequested] = useState<{ id: number, name: string, mobility: number, selected: string }[]>([])

    const {
        // The meta props of the Field.
        validationMessage,
        touched,
        visited,
        modified,
        valid,
        // The input props of the Field.
        value,
        id,
        disabled,
        optional,
        label,
        onChange,
        onFocus,
        onBlur,
        // The custom props that you passed to the Field.
        ...others
    } = fieldRenderProps;


    useEffect(() => {

        const equipmentService = new EquipmentService();
        async function fetchEquipmentData() {
            const startDate = moment(others.request.dateDebut).format("YYYY-MM-D")
            let endDate = ""
            if (others.request.dateDebut === others.request.dateFin) {
                endDate = moment(others.request.dateFin).add(1, 'd').format("YYYY-MM-D")
            } else {
                endDate = moment(others.request.dateFin).format("YYYY-MM-D")
            }
            return await equipmentService.getAvailables(startDate, endDate);
        }
        fetchEquipmentData().then((result: any) => { if (result) initialiseEdit(result); dispatch(setLoading(false)) });

    }, [others.request.dateDebut, others.request.dateFin])

    function initialiseEdit(result: any) {
        let req: any[] = others.request.equipements || [];
        let ava: any[] = result || [];

        req = others.request.equipements.map((item: any) => ({ "id": item.id, "name": item.name || item.nom, "selected": false }))

        if (result) {
            if (others.request.equipements) {
                ava = ava.filter((item: any) => !others.request.equipements.find((item2: any) => item2.id === item.id))
            }
            ava = ava.map((item: any) => ({ "id": item.id, "name": item.nom, "selected": false }))
        }
        setAvailable([...ava]);
        setRequested(req);
    }

    const handleItemAvailableClick = (event: ListBoxItemClickEvent) => {

        const newAvailable = available.map((item: any) => {
            if (item.id === event.dataItem.id) {
                item[SELECTED_FIELD] = !item[SELECTED_FIELD];
            } else if (!event.nativeEvent.ctrlKey) {
                item[SELECTED_FIELD] = false;
            }
            return item;
        })
        const newRequested = requested.map((item: any) => {
            item[SELECTED_FIELD] = false;
            return item;
        })
        setAvailable(newAvailable);
        setRequested(newRequested);
    };

    const handleItemRequestedClick = (event: ListBoxItemClickEvent) => {

        const newRequested = requested.map((item: any) => {
            if (item.id === event.dataItem.id) {
                item[SELECTED_FIELD] = !item[SELECTED_FIELD];
            } else if (!event.nativeEvent.ctrlKey) {
                item[SELECTED_FIELD] = false;
            }
            return item;
        })
        const newAvailable = available.map((item: any) => {
            item[SELECTED_FIELD] = false;
            return item;
        })
        setAvailable(newAvailable);
        setRequested(newRequested);
    };

    const handleToolBarClick = async (e: ListBoxToolbarClickEvent) => {
        let toolName: string = e.toolName || "";
        let result: any = processListBoxData(
            available,
            requested,
            toolName,
            SELECTED_FIELD
        );
        const newAvailable = result.listBoxOneData.filter((element: any, index: number, self: any) => {
            return self.findIndex((t: any) => (
                t.id === element.id
            )) === index;
        });

        const newRequested = result.listBoxTwoData.filter((element: any, index: number, self: any) => {
            return self.findIndex((t: any) => (
                t.id === element.id)) === index;
        })
        setAvailable(newAvailable);
        setRequested(newRequested);

    };

    const showValidationMessage: string | false | null =
        visited && validationMessage;
    const errorId: string = showValidationMessage ? `${id}_error` : "";

    const callback = useCallback(() => {
        onChange({ value: requested });
    }, [onChange, requested]);

    useEffect(() => {
        callback();

    }, [callback, requested])

    return (
        <FieldWrapper className={"col" + (others.required ? " required" : "")} >
            <Label className="listbox-field-title"
                editorId={id}
                editorValid={valid}
                editorDisabled={disabled}
                optional={optional}>
                {label}
            </Label>
            <div className={"listbox-field-layout" + (showValidationMessage ? " listbox-error" : "")} onFocus={onFocus} onBlur={onBlur}>
                <div className="listbox-item listbox-one" onFocus={onFocus} onBlur={onBlur}>
                    <Label className="listbox-title">Disponibles</Label>
                    <ListBox
                        data={available}
                        textField="name"
                        selectedField={SELECTED_FIELD}
                        onItemClick={(e: ListBoxItemClickEvent) =>
                            handleItemAvailableClick(e)
                        }
                        toolbar={() => {
                            return (
                                <div onFocus={onFocus} onBlur={onBlur}>
                                    <ListBoxToolbar
                                        tools={[
                                            "moveUp",
                                            "moveDown",
                                            "transferTo",
                                            "transferFrom",
                                            "transferAllTo",
                                            "transferAllFrom",
                                        ]}
                                        data={available}
                                        dataConnected={requested}
                                        onToolClick={handleToolBarClick}
                                    />
                                </div>
                            );
                        }}
                    />
                </div>
                <div className="listbox-item k-pl-0">
                    <Label className="listbox-title">Sélectionnés</Label>
                    <ListBox
                        data={requested}
                        textField="name"
                        selectedField={SELECTED_FIELD}
                        onItemClick={(e: ListBoxItemClickEvent) =>
                            handleItemRequestedClick(e)
                        }
                    />
                    {showValidationMessage && (
                        <Error id={errorId}>{validationMessage}</Error>
                    )}
                </div>
            </div>
        </FieldWrapper>
    )

}

