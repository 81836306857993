export const Status = [
  { name: "Enregistrée", id: 0 },
  { name: "En Attente De Validation BLM", id: 1 },
  { name: "En Attente De Validation GL", id: 2 },
  { name: "Refusée par BLM", id: 3 },
  { name: "Refusée par GL", id: 4 },
  { name: "Validée", id: 5 },
  { name: "Annulée", id: 6 },
]
export const StatusLoan = [
  { name: "En Attente De Validation BLM", id: 1 },
  { name: "En Attente De Validation GL", id: 2 },
  { name: "Refusée par BLM", id: 3 },
  { name: "Refusée par GL", id: 4 },
  { name: "Validée", id: 5 },
  { name: "Annulée", id: 6 },
]
export const StatusDemo = [
  { name: "En Attente De Validation GL", id: 2 },
  { name: "Refusée par GL", id: 4 },
  { name: "Validée", id: 5 },
  { name: "Annulée", id: 6 },
]